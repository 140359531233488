/**
 * Generated by orval v6.23.0 🍺
 * Do not edit manually.
 * Aromoshelf Admin API
 * OpenAPI spec version: 1.0
 */
import { getAxios } from './api.mutator';
export type PostGetAllParams = {
	offset?: number;
	limit?: number;
	filter?: string;
	sort?: string;
	sortDir?: SortDir;
	filterModel?: string;
	strategy?: FTSStrategy;
};

export type UserGetPostsParams = {
	offset?: number;
	limit?: number;
	filter?: string;
	sort?: string;
	sortDir?: SortDir;
	filterModel?: string;
	strategy?: FTSStrategy;
};

export type UserGetExperiencesShelfId = (typeof UserGetExperiencesShelfId)[keyof typeof UserGetExperiencesShelfId];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const UserGetExperiencesShelfId = {
	NUMBER_51: 51,
} as const;

export type UserGetExperiencesParams = {
	offset?: number;
	limit?: number;
	filter?: string;
	sort?: string;
	sortDir?: SortDir;
	filterModel?: string;
	strategy?: FTSStrategy;
	shelfId?: UserGetExperiencesShelfId;
};

export type UserGetAllParams = {
	offset?: number;
	limit?: number;
	filter?: string;
	sort?: string;
	sortDir?: SortDir;
	filterModel?: string;
	strategy?: FTSStrategy;
	role?: UserRole;
};

export type IngredientGetAllParams = {
	offset?: number;
	limit?: number;
	filter?: string;
	sort?: string;
	sortDir?: SortDir;
	filterModel?: string;
	strategy?: FTSStrategy;
};

export type SnapshotGetAllParams = {
	offset?: number;
	limit?: number;
	filter?: string;
	sort?: string;
	sortDir?: SortDir;
	filterModel?: string;
	strategy?: FTSStrategy;
};

export type PushCampaignGetAllParams = {
	offset?: number;
	limit?: number;
	filter?: string;
	sort?: string;
	sortDir?: SortDir;
	filterModel?: string;
	strategy?: FTSStrategy;
};

export type ChangeLogGetAllParams = {
	offset?: number;
	limit?: number;
	filter?: string;
	sort?: string;
	sortDir?: SortDir;
	filterModel?: string;
	strategy?: FTSStrategy;
	entity?: ChangeLogEntity;
	entityId?: number;
};

export type FeedbackGetAllParams = {
	offset?: number;
	limit?: number;
	filter?: string;
	sort?: string;
	sortDir?: SortDir;
	filterModel?: string;
	strategy?: FTSStrategy;
};

export type FragranceGetReviewsParams = {
	offset?: number;
	limit?: number;
};

export type FragranceGetExperiencesParams = {
	offset?: number;
	limit?: number;
};

export type FragranceExportCSVParams = {
	offset?: number;
	limit?: number;
	filter?: string;
	sort?: string;
	sortDir?: SortDir;
	filterModel?: string;
	strategy?: FTSStrategy;
};

export type FragranceGetAllParams = {
	offset?: number;
	limit?: number;
	filter?: string;
	sort?: string;
	sortDir?: SortDir;
	filterModel?: string;
	strategy?: FTSStrategy;
};

export type PerfumerGetAllParams = {
	offset?: number;
	limit?: number;
	filter?: string;
	sort?: string;
	sortDir?: SortDir;
	filterModel?: string;
	strategy?: FTSStrategy;
};

export type NoteGetAllParams = {
	offset?: number;
	limit?: number;
	filter?: string;
	sort?: string;
	sortDir?: SortDir;
	filterModel?: string;
	strategy?: FTSStrategy;
};

export type BrandGetAllParams = {
	offset?: number;
	limit?: number;
	filter?: string;
	sort?: string;
	sortDir?: SortDir;
	filterModel?: string;
	strategy?: FTSStrategy;
};

export interface SettingsDto {
	fragranceSearchExcludeStrings?: string[];
	imgBrandBig: string;
	imgBrandSmall: string;
	imgFragrance: string;
	imgIngredient: string;
	imgNote: string;
	imgPerfumer: string;
	imgUser: string;
}

export interface Settings {
	fragranceSearchExcludeStrings?: string[];
	imgBrandBig: string;
	imgBrandSmall: string;
	imgFragrance: string;
	imgIngredient: string;
	imgNote: string;
	imgPerfumer: string;
	imgUser: string;
}

export type RecommendsListsDtoNewbiesItem =
	(typeof RecommendsListsDtoNewbiesItem)[keyof typeof RecommendsListsDtoNewbiesItem];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const RecommendsListsDtoNewbiesItem = {
	NUMBER_43: 43,
} as const;

export type RecommendsListsDtoInitiatedItem =
	(typeof RecommendsListsDtoInitiatedItem)[keyof typeof RecommendsListsDtoInitiatedItem];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const RecommendsListsDtoInitiatedItem = {
	NUMBER_43: 43,
} as const;

export interface RecommendsListsDto {
	initiated: RecommendsListsDtoInitiatedItem[];
	newbies: RecommendsListsDtoNewbiesItem[];
}

export interface RecommendsLists {
	initiated: FragranceItem[];
	newbies: FragranceItem[];
}

export interface StopWordsDto {
	reserved: string[];
	swear: string[];
}

export interface StopWordList {
	reserved: string[];
	swear: string[];
}

export interface PostModerationDto {
	moderationStatus: ModerationStatus;
}

export type PostUpdateDtoFragranceId = (typeof PostUpdateDtoFragranceId)[keyof typeof PostUpdateDtoFragranceId];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const PostUpdateDtoFragranceId = {
	NUMBER_43: 43,
} as const;

export interface PostUpdateDto {
	city?: string;
	country?: string;
	fragranceId: PostUpdateDtoFragranceId;
	publishAt?: Date;
	rating?: number;
	temperature?: number;
	text?: string;
	weather?: string;
}

export type PostCreateDtoUserId = (typeof PostCreateDtoUserId)[keyof typeof PostCreateDtoUserId];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const PostCreateDtoUserId = {
	NUMBER_48: 48,
} as const;

export type PostCreateDtoFragranceId = (typeof PostCreateDtoFragranceId)[keyof typeof PostCreateDtoFragranceId];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const PostCreateDtoFragranceId = {
	NUMBER_43: 43,
} as const;

export interface PostCreateDto {
	city?: string;
	country?: string;
	fragranceId: PostCreateDtoFragranceId;
	publishAt?: Date;
	rating?: number;
	temperature?: number;
	text?: string;
	userId: PostCreateDtoUserId;
	weather?: string;
}

export type PostUserId = (typeof PostUserId)[keyof typeof PostUserId];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const PostUserId = {
	NUMBER_48: 48,
} as const;

export type PostId = (typeof PostId)[keyof typeof PostId];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const PostId = {
	NUMBER_52: 52,
} as const;

export type PostFragranceId = (typeof PostFragranceId)[keyof typeof PostFragranceId];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const PostFragranceId = {
	NUMBER_43: 43,
} as const;

export interface Post {
	city?: string;
	country?: string;
	createdAt: Date;
	fragranceId: PostFragranceId;
	id: PostId;
	lang?: string;
	publishAt?: Date;
	rating?: number;
	temperature?: number;
	text?: string;
	updatedAt: Date;
	userId: PostUserId;
	weather?: string;
}

export interface PostList {
	items: PostItem[];
	total: number;
}

export type PostItemUserId = (typeof PostItemUserId)[keyof typeof PostItemUserId];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const PostItemUserId = {
	NUMBER_48: 48,
} as const;

export type PostItemId = (typeof PostItemId)[keyof typeof PostItemId];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const PostItemId = {
	NUMBER_52: 52,
} as const;

export type PostItemFragranceId = (typeof PostItemFragranceId)[keyof typeof PostItemFragranceId];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const PostItemFragranceId = {
	NUMBER_43: 43,
} as const;

export interface PostItem {
	brand: string;
	city?: string;
	country?: string;
	createdAt: Date;
	fragrance: string;
	fragranceId: PostItemFragranceId;
	id: PostItemId;
	image?: string;
	publishAt?: Date | null;
	text?: string;
	updatedAt: Date;
	user: string;
	userId: PostItemUserId;
}

export interface UserMessageDto {
	analyticsLabel?: string;
	description?: string;
	imgLeft?: string;
	imgRight?: string;
	message: string;
	title: string;
}

export interface UserPostList {
	items: UserPostItem[];
	total: number;
}

export type UserPostItemId = (typeof UserPostItemId)[keyof typeof UserPostItemId];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const UserPostItemId = {
	NUMBER_52: 52,
} as const;

export type UserPostItemFragranceId = (typeof UserPostItemFragranceId)[keyof typeof UserPostItemFragranceId];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const UserPostItemFragranceId = {
	NUMBER_43: 43,
} as const;

export interface UserPostItem {
	city?: string;
	country?: string;
	createdAt: Date;
	fragranceId: UserPostItemFragranceId;
	fragranceName: string;
	id: UserPostItemId;
	image?: string;
	lang?: string;
	moderation?: ModerationStatus;
	rating?: number;
	temperature?: number;
	text?: string;
	updatedAt: Date;
	weather?: string;
}

export interface UserChangeRoleDto {
	role: UserRole;
}

export interface UserChangeCommentDto {
	comment: string;
}

export interface UserChangeStatusDto {
	status: UserStatus;
}

export type UserExperienceId = (typeof UserExperienceId)[keyof typeof UserExperienceId];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const UserExperienceId = {
	NUMBER_49: 49,
} as const;

export type UserExperienceFragranceId = (typeof UserExperienceFragranceId)[keyof typeof UserExperienceFragranceId];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const UserExperienceFragranceId = {
	NUMBER_43: 43,
} as const;

export type UserExperienceBrandId = (typeof UserExperienceBrandId)[keyof typeof UserExperienceBrandId];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const UserExperienceBrandId = {
	NUMBER_42: 42,
} as const;

export type UserExperienceShelfId = (typeof UserExperienceShelfId)[keyof typeof UserExperienceShelfId];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const UserExperienceShelfId = {
	NUMBER_51: 51,
} as const;

export interface UserExperienceShelf {
	id: UserExperienceShelfId;
	name: string;
}

export interface UserExperience {
	batchCode?: string;
	brandId: UserExperienceBrandId;
	brandName: string;
	comment?: string;
	commentDate?: Date;
	concentration?: Concentration;
	createdAt: Date;
	fragranceId: UserExperienceFragranceId;
	fragranceName: string;
	id: UserExperienceId;
	image?: string;
	longevity?: Longevity;
	notes: UserExperienceNote[];
	photo?: string;
	rating?: number;
	ratingDate?: Date;
	reminds: UserExperienceRemind[];
	shelves: UserExperienceShelf[];
	sillage?: Sillage;
	size?: FragranceSize;
	updatedAt: Date;
	volume?: number;
	volumeUnit?: VolumeUnit;
	year?: number | null;
}

export type UserExperienceNoteId = (typeof UserExperienceNoteId)[keyof typeof UserExperienceNoteId] | null;

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const UserExperienceNoteId = {
	NUMBER_44: 44,
} as const;

export interface UserExperienceNote {
	id?: UserExperienceNoteId;
	name: string;
}

export type UserExperienceRemindId = (typeof UserExperienceRemindId)[keyof typeof UserExperienceRemindId];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const UserExperienceRemindId = {
	NUMBER_43: 43,
} as const;

export interface UserExperienceRemind {
	id: UserExperienceRemindId;
	name: string;
}

export type UserExperienceItemId = (typeof UserExperienceItemId)[keyof typeof UserExperienceItemId];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const UserExperienceItemId = {
	NUMBER_49: 49,
} as const;

export type UserExperienceItemFragranceId =
	(typeof UserExperienceItemFragranceId)[keyof typeof UserExperienceItemFragranceId];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const UserExperienceItemFragranceId = {
	NUMBER_43: 43,
} as const;

export type UserExperienceItemBrandId = (typeof UserExperienceItemBrandId)[keyof typeof UserExperienceItemBrandId];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const UserExperienceItemBrandId = {
	NUMBER_42: 42,
} as const;

export type VolumeUnit = (typeof VolumeUnit)[keyof typeof VolumeUnit];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const VolumeUnit = {
	ML: 'ML',
	OZ: 'OZ',
} as const;

export type FragranceSize = (typeof FragranceSize)[keyof typeof FragranceSize];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const FragranceSize = {
	STRIP: 'STRIP',
	DECANT: 'DECANT',
	SAMPLE: 'SAMPLE',
	BOTTLE: 'BOTTLE',
} as const;

export interface UserExperienceItem {
	brandId: UserExperienceItemBrandId;
	brandName: string;
	concentration?: Concentration;
	createdAt: Date;
	fragranceId: UserExperienceItemFragranceId;
	fragranceName: string;
	id: UserExperienceItemId;
	image?: string;
	longevity?: Longevity;
	rating?: number;
	ratingDate?: Date;
	sillage?: Sillage;
	size?: FragranceSize;
	updatedAt: Date;
	volume?: number;
	volumeUnit?: VolumeUnit;
	year?: number | null;
}

export interface UserExperienceList {
	items: UserExperienceItem[];
	total: number;
}

export type UserId = (typeof UserId)[keyof typeof UserId];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const UserId = {
	NUMBER_48: 48,
} as const;

export interface User {
	activityDate?: Date;
	agreedTermsAt?: Date;
	avatar: string;
	bio?: string;
	comment?: string;
	createdAt: Date;
	devices: UserDevice[];
	email: string;
	experienceCount: number;
	fragranceVoteWeight: number;
	id: UserId;
	lastSeenAt: Date;
	nickName: string;
	nickNameHistory?: string[];
	oauthProvider?: OAuthProvider;
	postCount: number;
	refLink: string;
	role?: UserRole;
	shelves: UserShelf[];
	status: UserStatus;
}

export type UserDeviceStatus = (typeof UserDeviceStatus)[keyof typeof UserDeviceStatus];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const UserDeviceStatus = {
	ACTIVE: 'ACTIVE',
	UNREACHABLE: 'UNREACHABLE',
} as const;

export interface UserDevice {
	appVersion?: string | null;
	brand?: string | null;
	coarceLocationPermission?: string | null;
	fineLocationPermission?: string | null;
	id: number;
	lastSeenAt?: Date | null;
	model?: string | null;
	notificationPermission?: string | null;
	pixelRatio?: number | null;
	status: UserDeviceStatus;
	timezone?: string | null;
}

export interface UserShelf {
	fragranceCount?: number;
	id: number;
	name: string;
}

export type UserItemId = (typeof UserItemId)[keyof typeof UserItemId];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const UserItemId = {
	NUMBER_48: 48,
} as const;

export type OAuthProvider = (typeof OAuthProvider)[keyof typeof OAuthProvider];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const OAuthProvider = {
	GOOGLE: 'GOOGLE',
	APPLE: 'APPLE',
} as const;

export type UserStatus = (typeof UserStatus)[keyof typeof UserStatus];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const UserStatus = {
	NEW: 'NEW',
	ACTIVE: 'ACTIVE',
	BAN: 'BAN',
	FAKE: 'FAKE',
} as const;

export type UserRole = (typeof UserRole)[keyof typeof UserRole];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const UserRole = {
	REGULAR: 'REGULAR',
	TESTER: 'TESTER',
	WRITER: 'WRITER',
} as const;

export interface UserItem {
	avatar: string;
	comment?: string;
	createdAt: Date;
	email: string;
	experienceCount: number;
	id: UserItemId;
	lastSeenAt: Date;
	nickName: string;
	oauthProvider?: OAuthProvider;
	postCount: number;
	role?: UserRole;
	status: UserStatus;
}

export interface UserList {
	items: UserItem[];
	total: number;
}

export type IngredientUpdateDtoAnalogsItem =
	(typeof IngredientUpdateDtoAnalogsItem)[keyof typeof IngredientUpdateDtoAnalogsItem];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const IngredientUpdateDtoAnalogsItem = {
	NUMBER_62: 62,
} as const;

export interface IngredientUpdateDto {
	analogs?: IngredientUpdateDtoAnalogsItem[];
	casNumber?: string;
	description?: string;
	images?: string[];
	name: string;
	vendor?: string;
}

export type IngredientCreateDtoAnalogsItem =
	(typeof IngredientCreateDtoAnalogsItem)[keyof typeof IngredientCreateDtoAnalogsItem];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const IngredientCreateDtoAnalogsItem = {
	NUMBER_62: 62,
} as const;

export interface IngredientCreateDto {
	analogs?: IngredientCreateDtoAnalogsItem[];
	casNumber?: string;
	description?: string;
	images?: string[];
	name: string;
	vendor?: string;
}

export type IngredientId = (typeof IngredientId)[keyof typeof IngredientId];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const IngredientId = {
	NUMBER_62: 62,
} as const;

export type IngredientAnalogsItem = (typeof IngredientAnalogsItem)[keyof typeof IngredientAnalogsItem];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const IngredientAnalogsItem = {
	NUMBER_62: 62,
} as const;

export interface Ingredient {
	analogs?: IngredientAnalogsItem[];
	casNumber?: string;
	description?: string;
	id: IngredientId;
	images?: string[];
	name: string;
	vendor?: string;
}

export type IngredientNameId = (typeof IngredientNameId)[keyof typeof IngredientNameId];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const IngredientNameId = {
	NUMBER_62: 62,
} as const;

export interface IngredientName {
	id: IngredientNameId;
	name: string;
}

export interface IngredientNames {
	items: IngredientName[];
}

export type IngredientItemId = (typeof IngredientItemId)[keyof typeof IngredientItemId];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const IngredientItemId = {
	NUMBER_62: 62,
} as const;

export interface IngredientItem {
	casNumber?: string;
	description?: string;
	id: IngredientItemId;
	image: string;
	name: string;
	vendor?: string;
}

export interface IngredientList {
	items: IngredientItem[];
	total: number;
}

export type SnapshotRestoreStatus = (typeof SnapshotRestoreStatus)[keyof typeof SnapshotRestoreStatus];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const SnapshotRestoreStatus = {
	IDLE: 'IDLE',
	PROGRESS: 'PROGRESS',
	ERROR: 'ERROR',
	FINISHED: 'FINISHED',
	CANCELLED: 'CANCELLED',
} as const;

export interface Snapshot {
	createdAt: Date;
	createdBy: string;
	id: string;
}

export interface SnapshotList {
	items: Snapshot[];
	restoreStatus: SnapshotRestoreStatus;
	total: number;
}

export interface PushCampaignUpdateDto {
	analyticsLabel: string;
	audienceConditions: PushAudienceConditions;
	badge: boolean;
	campaignName: string;
	description?: string;
	imgLeft?: string;
	imgRight?: string;
	light: boolean;
	message: string;
	noticeTTL?: number | null;
	pushTTL?: number | null;
	sendAt: Date;
	sendNotice: boolean;
	sendPush: boolean;
	sound: boolean;
	status: PushCampaignStatus;
	title: string;
	vibrate: boolean;
}

export interface PushCampaignCreateDto {
	analyticsLabel: string;
	audienceConditions: PushAudienceConditions;
	badge: boolean;
	campaignName: string;
	description?: string;
	imgLeft?: string;
	imgRight?: string;
	light: boolean;
	message: string;
	noticeTTL?: number | null;
	pushTTL?: number | null;
	sendAt: Date;
	sendNotice: boolean;
	sendPush: boolean;
	sound: boolean;
	status: PushCampaignStatus;
	title: string;
	vibrate: boolean;
}

export type PushCampaignId = (typeof PushCampaignId)[keyof typeof PushCampaignId];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const PushCampaignId = {
	NUMBER_60: 60,
} as const;

export interface PushCampaign {
	analyticsLabel: string;
	audienceConditions: PushAudienceConditions;
	badge: boolean;
	campaignName: string;
	createdAt: Date;
	createdByName?: string | null;
	description?: string;
	errorCount: number;
	id: PushCampaignId;
	imgLeft?: string;
	imgRight?: string;
	light: boolean;
	message: string;
	noticeCount: number;
	noticeTTL?: number;
	pushCount: number;
	pushTTL?: number;
	sendAt: Date;
	sendNotice: boolean;
	sendPush: boolean;
	sound: boolean;
	status: PushCampaignStatus;
	title: string;
	vibrate: boolean;
}

export interface PushCampaignList {
	items: PushCampaignItem[];
	total: number;
}

export type PushCampaignItemId = (typeof PushCampaignItemId)[keyof typeof PushCampaignItemId];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const PushCampaignItemId = {
	NUMBER_60: 60,
} as const;

export interface PushExperienceRatingCondition {
	hasNoExperiences?: boolean;
	percentOfSetRates: number;
}

export interface PushAudienceConditions {
	experienceRating?: PushExperienceRatingCondition;
	user?: PushUserCondition;
}

export interface PushCampaignItem {
	analyticsLabel: string;
	audienceConditions: PushAudienceConditions;
	badge: boolean;
	campaignName: string;
	createdAt: Date;
	createdByName?: string | null;
	description?: string;
	errorCount: number;
	id: PushCampaignItemId;
	imgLeft?: string;
	imgRight?: string;
	light: boolean;
	message: string;
	noticeCount: number;
	noticeTTL?: number;
	pushCount: number;
	pushTTL?: number;
	sendAt: Date;
	sendNotice: boolean;
	sendPush: boolean;
	sound: boolean;
	status: PushCampaignStatus;
	title: string;
	vibrate: boolean;
}

export type PushUserConditionUserIdsItem =
	(typeof PushUserConditionUserIdsItem)[keyof typeof PushUserConditionUserIdsItem];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const PushUserConditionUserIdsItem = {
	NUMBER_48: 48,
} as const;

export interface PushUserCondition {
	userIds: PushUserConditionUserIdsItem[];
}

export type PushCampaignStatus = (typeof PushCampaignStatus)[keyof typeof PushCampaignStatus];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const PushCampaignStatus = {
	DRAFT: 'DRAFT',
	SCHEDULED: 'SCHEDULED',
	ONGOING: 'ONGOING',
	FINISHED: 'FINISHED',
	CANCELED: 'CANCELED',
} as const;

export type ChangeLogItemStaffId = (typeof ChangeLogItemStaffId)[keyof typeof ChangeLogItemStaffId];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const ChangeLogItemStaffId = {
	NUMBER_47: 47,
} as const;

export type ChangeLogItemId = (typeof ChangeLogItemId)[keyof typeof ChangeLogItemId];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const ChangeLogItemId = {
	NUMBER_56: 56,
} as const;

export type ChangeLogAction = (typeof ChangeLogAction)[keyof typeof ChangeLogAction];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const ChangeLogAction = {
	CREATE: 'CREATE',
	UPDATE: 'UPDATE',
	DELETE: 'DELETE',
} as const;

export type ChangeLogEntity = (typeof ChangeLogEntity)[keyof typeof ChangeLogEntity];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const ChangeLogEntity = {
	FRAGRANCE: 'FRAGRANCE',
	BRAND: 'BRAND',
	PERFUMER: 'PERFUMER',
} as const;

export interface ChangeLogItem {
	action: ChangeLogAction;
	comment?: string;
	createdAt: Date;
	diff: string;
	entity: ChangeLogEntity;
	entityId: number;
	id: ChangeLogItemId;
	staffId: ChangeLogItemStaffId;
	staffName: string;
	title: string;
}

export interface ChangeLogList {
	items: ChangeLogItem[];
	total: number;
}

export interface UploadImagesResponse {
	ids: string[];
}

export interface FileUploadDto {
	files: Blob[];
}

export interface UploadPhotoResponse {
	fileId: string;
}

export interface UploadUrlList {
	urls: string[];
}

export interface UploadUrlRequest {
	fileName: string;
	fileType: string;
}

export interface UploadUrlBody {
	files: UploadUrlRequest[];
}

export type OlfactiveNameId = (typeof OlfactiveNameId)[keyof typeof OlfactiveNameId];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const OlfactiveNameId = {
	NUMBER_45: 45,
} as const;

export interface OlfactiveName {
	id: OlfactiveNameId;
	name: string;
}

export interface OlfactiveNames {
	items: OlfactiveName[];
}

export type FeedbackReplyDtoFragranceId =
	(typeof FeedbackReplyDtoFragranceId)[keyof typeof FeedbackReplyDtoFragranceId];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const FeedbackReplyDtoFragranceId = {
	NUMBER_43: 43,
} as const;

export interface FeedbackReplyDto {
	analyticsLabel?: string;
	description?: string;
	fragranceId?: FeedbackReplyDtoFragranceId;
	imgLeft?: string;
	imgRight?: string;
	message: string;
	status?: FeedbackStatus;
	title: string;
}

export interface FeedbackChangeStatusDto {
	status: FeedbackStatus;
}

export interface FeedbackList {
	items: Feedback[];
	total: number;
}

export type FeedbackUserId = (typeof FeedbackUserId)[keyof typeof FeedbackUserId];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const FeedbackUserId = {
	NUMBER_48: 48,
} as const;

export type FeedbackPostId = (typeof FeedbackPostId)[keyof typeof FeedbackPostId];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const FeedbackPostId = {
	NUMBER_52: 52,
} as const;

export type FeedbackPostAuthorId = (typeof FeedbackPostAuthorId)[keyof typeof FeedbackPostAuthorId];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const FeedbackPostAuthorId = {
	NUMBER_48: 48,
} as const;

export type FeedbackId = (typeof FeedbackId)[keyof typeof FeedbackId];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const FeedbackId = {
	NUMBER_50: 50,
} as const;

export type FeedbackFragranceId = (typeof FeedbackFragranceId)[keyof typeof FeedbackFragranceId];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const FeedbackFragranceId = {
	NUMBER_43: 43,
} as const;

export type FeedbackCreatedBy = (typeof FeedbackCreatedBy)[keyof typeof FeedbackCreatedBy];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const FeedbackCreatedBy = {
	NUMBER_48: 48,
} as const;

export type FeedbackStatus = (typeof FeedbackStatus)[keyof typeof FeedbackStatus];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const FeedbackStatus = {
	NEW: 'NEW',
	DONE: 'DONE',
	DECLINED: 'DECLINED',
} as const;

export type FeedbackType = (typeof FeedbackType)[keyof typeof FeedbackType];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const FeedbackType = {
	FRAG_SUGGESTION: 'FRAG_SUGGESTION',
	POST_COMPLAINT: 'POST_COMPLAINT',
	USER_COMPLAINT: 'USER_COMPLAINT',
	CONTACT: 'CONTACT',
	PHOTO_COMPLAINT: 'PHOTO_COMPLAINT',
	REVIEW_COMPLAINT: 'REVIEW_COMPLAINT',
	RATE: 'RATE',
} as const;

export interface Feedback {
	appVersion?: string | null;
	complaint?: ComplaintType;
	createdAt: Date;
	createdBy: FeedbackCreatedBy;
	createdByEmail: string;
	createdByName: string;
	fragranceId?: FeedbackFragranceId;
	id: FeedbackId;
	message: string;
	postAuthor?: string;
	postAuthorId?: FeedbackPostAuthorId;
	postId?: FeedbackPostId;
	postModeration?: ModerationStatus;
	postText?: string;
	rate?: number | null;
	status: FeedbackStatus;
	statusChangedAt?: Date;
	statusChangedBy?: string;
	type: FeedbackType;
	updatedAt: Date;
	userId?: FeedbackUserId;
	userName?: string;
}

export type FragranceReviewUserId = (typeof FragranceReviewUserId)[keyof typeof FragranceReviewUserId];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const FragranceReviewUserId = {
	NUMBER_48: 48,
} as const;

export type ModerationStatus = (typeof ModerationStatus)[keyof typeof ModerationStatus];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const ModerationStatus = {
	NEED_MODERATION: 'NEED_MODERATION',
	WAIT_MODERATION: 'WAIT_MODERATION',
	APPROVED: 'APPROVED',
	HIDDEN: 'HIDDEN',
} as const;

export interface FragranceReviewStatusDto {
	status: ModerationStatus;
}

export interface FragranceReview {
	createdAt: Date;
	moderation?: ModerationStatus;
	rating: number;
	review: string;
	userId: FragranceReviewUserId;
	userNickName: string;
}

export interface FragranceReviewList {
	items: FragranceReview[];
}

export type FragranceExperienceUserId = (typeof FragranceExperienceUserId)[keyof typeof FragranceExperienceUserId];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const FragranceExperienceUserId = {
	NUMBER_48: 48,
} as const;

export interface FragranceExperience {
	userId: FragranceExperienceUserId;
	userNickName: string;
}

export interface FragranceExperienceList {
	items: FragranceExperience[];
}

export interface UrlInfo {
	title: string;
}

export interface UrlInfoDto {
	url: string;
}

export type FragranceSetSuperDtoSuperFragranceId =
	| (typeof FragranceSetSuperDtoSuperFragranceId)[keyof typeof FragranceSetSuperDtoSuperFragranceId]
	| null;

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const FragranceSetSuperDtoSuperFragranceId = {
	NUMBER_43: 43,
} as const;

export interface FragranceSetSuperDto {
	superFragranceId: FragranceSetSuperDtoSuperFragranceId;
}

export interface FragranceImportDto {
	[key: string]: any;
}

export interface FragranceImport {
	fileName: string;
	items: FragranceImportDto[];
}

export interface CSVResponse {
	csv: string;
}

export interface FragranceChangePhotoComplaintDto {
	clear: boolean;
	fileId: string;
}

export type FragranceUpdateDtoPerfumersItem =
	(typeof FragranceUpdateDtoPerfumersItem)[keyof typeof FragranceUpdateDtoPerfumersItem];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const FragranceUpdateDtoPerfumersItem = {
	NUMBER_46: 46,
} as const;

export type FragranceUpdateDtoOlfactiveId =
	(typeof FragranceUpdateDtoOlfactiveId)[keyof typeof FragranceUpdateDtoOlfactiveId];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const FragranceUpdateDtoOlfactiveId = {
	NUMBER_45: 45,
} as const;

export type FragranceUpdateDtoNoseWeatherItem =
	(typeof FragranceUpdateDtoNoseWeatherItem)[keyof typeof FragranceUpdateDtoNoseWeatherItem];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const FragranceUpdateDtoNoseWeatherItem = {
	RAIN: 'RAIN',
	SNOW: 'SNOW',
	SUNNY: 'SUNNY',
	WINDY: 'WINDY',
} as const;

export type FragranceUpdateDtoNoseSeasonItem =
	(typeof FragranceUpdateDtoNoseSeasonItem)[keyof typeof FragranceUpdateDtoNoseSeasonItem];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const FragranceUpdateDtoNoseSeasonItem = {
	WINTER: 'WINTER',
	SPRING: 'SPRING',
	SUMMER: 'SUMMER',
	AUTUMN: 'AUTUMN',
} as const;

export type FragranceUpdateDtoNoseOccasionItem =
	(typeof FragranceUpdateDtoNoseOccasionItem)[keyof typeof FragranceUpdateDtoNoseOccasionItem];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const FragranceUpdateDtoNoseOccasionItem = {
	DAILY: 'DAILY',
	NIGHTLY: 'NIGHTLY',
	OFFICE: 'OFFICE',
	SPORTS: 'SPORTS',
	SPECIAL: 'SPECIAL',
	LEASURE: 'LEASURE',
	BEDTIME: 'BEDTIME',
} as const;

export type FragranceUpdateDtoNoseNotesItem =
	(typeof FragranceUpdateDtoNoseNotesItem)[keyof typeof FragranceUpdateDtoNoseNotesItem];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const FragranceUpdateDtoNoseNotesItem = {
	NUMBER_44: 44,
} as const;

export type FragranceUpdateDtoIngredientsItem =
	(typeof FragranceUpdateDtoIngredientsItem)[keyof typeof FragranceUpdateDtoIngredientsItem];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const FragranceUpdateDtoIngredientsItem = {
	NUMBER_62: 62,
} as const;

export type FragranceUpdateDtoBrandTopNotesItem =
	(typeof FragranceUpdateDtoBrandTopNotesItem)[keyof typeof FragranceUpdateDtoBrandTopNotesItem];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const FragranceUpdateDtoBrandTopNotesItem = {
	NUMBER_44: 44,
} as const;

export type FragranceUpdateDtoBrandMidNotesItem =
	(typeof FragranceUpdateDtoBrandMidNotesItem)[keyof typeof FragranceUpdateDtoBrandMidNotesItem];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const FragranceUpdateDtoBrandMidNotesItem = {
	NUMBER_44: 44,
} as const;

export type FragranceUpdateDtoBrandId = (typeof FragranceUpdateDtoBrandId)[keyof typeof FragranceUpdateDtoBrandId];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const FragranceUpdateDtoBrandId = {
	NUMBER_42: 42,
} as const;

export type FragranceUpdateDtoBrandBaseNotesItem =
	(typeof FragranceUpdateDtoBrandBaseNotesItem)[keyof typeof FragranceUpdateDtoBrandBaseNotesItem];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const FragranceUpdateDtoBrandBaseNotesItem = {
	NUMBER_44: 44,
} as const;

export interface FragranceUpdateDto {
	active?: boolean;
	brandBaseNotes?: FragranceUpdateDtoBrandBaseNotesItem[];
	brandId: FragranceUpdateDtoBrandId;
	brandMidNotes?: FragranceUpdateDtoBrandMidNotesItem[];
	brandTopNotes?: FragranceUpdateDtoBrandTopNotesItem[];
	comment?: string;
	concentration?: Concentration;
	description?: string;
	discontinued?: boolean;
	gender?: Gender;
	hiddenPhotos?: FragrancePhotoDto[];
	ingredients?: FragranceUpdateDtoIngredientsItem[];
	links: FragranceLinkDto[];
	longevity?: Longevity;
	name: string;
	noseConcentration?: Concentration;
	noseLongevity?: Longevity;
	noseNotes?: FragranceUpdateDtoNoseNotesItem[];
	noseOccasion?: FragranceUpdateDtoNoseOccasionItem[] | null;
	noseSeason?: FragranceUpdateDtoNoseSeasonItem[] | null;
	noseSillage?: Sillage;
	noseWeather?: FragranceUpdateDtoNoseWeatherItem[] | null;
	olfactiveId?: FragranceUpdateDtoOlfactiveId;
	perfumers?: FragranceUpdateDtoPerfumersItem[];
	preliminaryPhotos?: FragrancePhotoDto[];
	privatePhotos?: FragrancePhotoDto[];
	publicPhotos?: FragrancePhotoDto[];
	sillage?: Sillage;
	synonyms?: string[];
	years?: number[];
}

export type FragranceCreateDtoPerfumersItem =
	(typeof FragranceCreateDtoPerfumersItem)[keyof typeof FragranceCreateDtoPerfumersItem];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const FragranceCreateDtoPerfumersItem = {
	NUMBER_46: 46,
} as const;

export type FragranceCreateDtoOlfactiveId =
	(typeof FragranceCreateDtoOlfactiveId)[keyof typeof FragranceCreateDtoOlfactiveId];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const FragranceCreateDtoOlfactiveId = {
	NUMBER_45: 45,
} as const;

export type FragranceCreateDtoNoseWeatherItem =
	(typeof FragranceCreateDtoNoseWeatherItem)[keyof typeof FragranceCreateDtoNoseWeatherItem];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const FragranceCreateDtoNoseWeatherItem = {
	RAIN: 'RAIN',
	SNOW: 'SNOW',
	SUNNY: 'SUNNY',
	WINDY: 'WINDY',
} as const;

export type FragranceCreateDtoNoseSeasonItem =
	(typeof FragranceCreateDtoNoseSeasonItem)[keyof typeof FragranceCreateDtoNoseSeasonItem];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const FragranceCreateDtoNoseSeasonItem = {
	WINTER: 'WINTER',
	SPRING: 'SPRING',
	SUMMER: 'SUMMER',
	AUTUMN: 'AUTUMN',
} as const;

export type FragranceCreateDtoNoseOccasionItem =
	(typeof FragranceCreateDtoNoseOccasionItem)[keyof typeof FragranceCreateDtoNoseOccasionItem];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const FragranceCreateDtoNoseOccasionItem = {
	DAILY: 'DAILY',
	NIGHTLY: 'NIGHTLY',
	OFFICE: 'OFFICE',
	SPORTS: 'SPORTS',
	SPECIAL: 'SPECIAL',
	LEASURE: 'LEASURE',
	BEDTIME: 'BEDTIME',
} as const;

export type FragranceCreateDtoNoseNotesItem =
	(typeof FragranceCreateDtoNoseNotesItem)[keyof typeof FragranceCreateDtoNoseNotesItem];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const FragranceCreateDtoNoseNotesItem = {
	NUMBER_44: 44,
} as const;

export type FragranceCreateDtoIngredientsItem =
	(typeof FragranceCreateDtoIngredientsItem)[keyof typeof FragranceCreateDtoIngredientsItem];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const FragranceCreateDtoIngredientsItem = {
	NUMBER_62: 62,
} as const;

export type FragranceCreateDtoId = (typeof FragranceCreateDtoId)[keyof typeof FragranceCreateDtoId];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const FragranceCreateDtoId = {
	NUMBER_43: 43,
} as const;

export type FragranceCreateDtoBrandTopNotesItem =
	(typeof FragranceCreateDtoBrandTopNotesItem)[keyof typeof FragranceCreateDtoBrandTopNotesItem];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const FragranceCreateDtoBrandTopNotesItem = {
	NUMBER_44: 44,
} as const;

export type FragranceCreateDtoBrandMidNotesItem =
	(typeof FragranceCreateDtoBrandMidNotesItem)[keyof typeof FragranceCreateDtoBrandMidNotesItem];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const FragranceCreateDtoBrandMidNotesItem = {
	NUMBER_44: 44,
} as const;

export type FragranceCreateDtoBrandId = (typeof FragranceCreateDtoBrandId)[keyof typeof FragranceCreateDtoBrandId];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const FragranceCreateDtoBrandId = {
	NUMBER_42: 42,
} as const;

export type FragranceCreateDtoBrandBaseNotesItem =
	(typeof FragranceCreateDtoBrandBaseNotesItem)[keyof typeof FragranceCreateDtoBrandBaseNotesItem];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const FragranceCreateDtoBrandBaseNotesItem = {
	NUMBER_44: 44,
} as const;

export type FragranceLinkDtoId = (typeof FragranceLinkDtoId)[keyof typeof FragranceLinkDtoId];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const FragranceLinkDtoId = {
	NUMBER_61: 61,
} as const;

export interface FragranceLinkDto {
	id?: FragranceLinkDtoId;
	subtitle: string;
	title: string;
	type: FragranceLinkType;
	url: string;
}

export interface FragranceCreateDto {
	active?: boolean;
	brandBaseNotes?: FragranceCreateDtoBrandBaseNotesItem[];
	brandId: FragranceCreateDtoBrandId;
	brandMidNotes?: FragranceCreateDtoBrandMidNotesItem[];
	brandTopNotes?: FragranceCreateDtoBrandTopNotesItem[];
	comment?: string;
	concentration?: Concentration;
	description?: string;
	discontinued?: boolean;
	gender?: Gender;
	hiddenPhotos?: FragrancePhotoDto[];
	id?: FragranceCreateDtoId;
	ingredients?: FragranceCreateDtoIngredientsItem[];
	links: FragranceLinkDto[];
	longevity?: Longevity;
	name: string;
	noseConcentration?: Concentration;
	noseLongevity?: Longevity;
	noseNotes?: FragranceCreateDtoNoseNotesItem[];
	noseOccasion?: FragranceCreateDtoNoseOccasionItem[] | null;
	noseSeason?: FragranceCreateDtoNoseSeasonItem[] | null;
	noseSillage?: Sillage;
	noseWeather?: FragranceCreateDtoNoseWeatherItem[] | null;
	olfactiveId?: FragranceCreateDtoOlfactiveId;
	perfumers?: FragranceCreateDtoPerfumersItem[];
	preliminaryPhotos?: FragrancePhotoDto[];
	privatePhotos?: FragrancePhotoDto[];
	publicPhotos?: FragrancePhotoDto[];
	sillage?: Sillage;
	synonyms?: string[];
	years?: number[];
}

export type FragrancePhotoDtoId = (typeof FragrancePhotoDtoId)[keyof typeof FragrancePhotoDtoId];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const FragrancePhotoDtoId = {
	NUMBER_59: 59,
} as const;

export type FragrancePhotoDtoAuthorId = (typeof FragrancePhotoDtoAuthorId)[keyof typeof FragrancePhotoDtoAuthorId];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const FragrancePhotoDtoAuthorId = {
	NUMBER_48: 48,
} as const;

export interface FragrancePhotoDto {
	authorId?: FragrancePhotoDtoAuthorId;
	authorName?: string;
	authorUser?: string;
	caption?: string;
	complainedByUser?: string;
	complaintStatus?: ComplaintType;
	fileId: string;
	id?: FragrancePhotoDtoId;
	url: string;
}

export type FragranceSuperId = (typeof FragranceSuperId)[keyof typeof FragranceSuperId];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const FragranceSuperId = {
	NUMBER_43: 43,
} as const;

export type FragrancePerfumersItem = (typeof FragrancePerfumersItem)[keyof typeof FragrancePerfumersItem];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const FragrancePerfumersItem = {
	NUMBER_46: 46,
} as const;

export type FragranceOlfactiveId = (typeof FragranceOlfactiveId)[keyof typeof FragranceOlfactiveId];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const FragranceOlfactiveId = {
	NUMBER_45: 45,
} as const;

export type FragranceNoseWeatherItem = (typeof FragranceNoseWeatherItem)[keyof typeof FragranceNoseWeatherItem];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const FragranceNoseWeatherItem = {
	RAIN: 'RAIN',
	SNOW: 'SNOW',
	SUNNY: 'SUNNY',
	WINDY: 'WINDY',
} as const;

export type FragranceNoseSeasonItem = (typeof FragranceNoseSeasonItem)[keyof typeof FragranceNoseSeasonItem];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const FragranceNoseSeasonItem = {
	WINTER: 'WINTER',
	SPRING: 'SPRING',
	SUMMER: 'SUMMER',
	AUTUMN: 'AUTUMN',
} as const;

export type FragranceNoseOccasionItem = (typeof FragranceNoseOccasionItem)[keyof typeof FragranceNoseOccasionItem];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const FragranceNoseOccasionItem = {
	DAILY: 'DAILY',
	NIGHTLY: 'NIGHTLY',
	OFFICE: 'OFFICE',
	SPORTS: 'SPORTS',
	SPECIAL: 'SPECIAL',
	LEASURE: 'LEASURE',
	BEDTIME: 'BEDTIME',
} as const;

export type FragranceNoseNotesItem = (typeof FragranceNoseNotesItem)[keyof typeof FragranceNoseNotesItem];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const FragranceNoseNotesItem = {
	NUMBER_44: 44,
} as const;

export type FragranceIngredientsItem = (typeof FragranceIngredientsItem)[keyof typeof FragranceIngredientsItem];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const FragranceIngredientsItem = {
	NUMBER_62: 62,
} as const;

export type FragranceId = (typeof FragranceId)[keyof typeof FragranceId];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const FragranceId = {
	NUMBER_43: 43,
} as const;

export type FragranceBrandTopNotesItem = (typeof FragranceBrandTopNotesItem)[keyof typeof FragranceBrandTopNotesItem];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const FragranceBrandTopNotesItem = {
	NUMBER_44: 44,
} as const;

export type FragranceBrandMidNotesItem = (typeof FragranceBrandMidNotesItem)[keyof typeof FragranceBrandMidNotesItem];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const FragranceBrandMidNotesItem = {
	NUMBER_44: 44,
} as const;

export type FragranceBrandId = (typeof FragranceBrandId)[keyof typeof FragranceBrandId];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const FragranceBrandId = {
	NUMBER_42: 42,
} as const;

export type FragranceBrandBaseNotesItem =
	(typeof FragranceBrandBaseNotesItem)[keyof typeof FragranceBrandBaseNotesItem];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const FragranceBrandBaseNotesItem = {
	NUMBER_44: 44,
} as const;

export type Season = (typeof Season)[keyof typeof Season];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const Season = {
	WINTER: 'WINTER',
	SPRING: 'SPRING',
	SUMMER: 'SUMMER',
	AUTUMN: 'AUTUMN',
} as const;

export interface FragranceSeason {
	season: Season;
	votes: number;
}

export type Occasion = (typeof Occasion)[keyof typeof Occasion];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const Occasion = {
	DAILY: 'DAILY',
	NIGHTLY: 'NIGHTLY',
	OFFICE: 'OFFICE',
	SPORTS: 'SPORTS',
	SPECIAL: 'SPECIAL',
	LEASURE: 'LEASURE',
	BEDTIME: 'BEDTIME',
} as const;

export interface FragranceOccasion {
	occasion: Occasion;
	votes: number;
}

export type Weather = (typeof Weather)[keyof typeof Weather];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const Weather = {
	RAIN: 'RAIN',
	SNOW: 'SNOW',
	SUNNY: 'SUNNY',
	WINDY: 'WINDY',
} as const;

export interface FragranceWeather {
	votes: number;
	weather: Weather;
}

export interface Fragrance {
	active: boolean;
	brandBaseNotes: FragranceBrandBaseNotesItem[];
	brandId: FragranceBrandId;
	brandMidNotes: FragranceBrandMidNotesItem[];
	brandTopNotes: FragranceBrandTopNotesItem[];
	clasped: FragranceClasped[];
	concentration?: Concentration;
	description?: string;
	discontinued: boolean;
	gender?: Gender;
	hiddenPhotos: FragrancePhoto[];
	id: FragranceId;
	ingredients: FragranceIngredientsItem[];
	links: FragranceLink[];
	longevity?: Longevity;
	name: string;
	noseConcentration?: Concentration;
	noseLongevity?: Longevity;
	noseNotes: FragranceNoseNotesItem[];
	noseOccasion?: FragranceNoseOccasionItem[];
	noseSeason?: FragranceNoseSeasonItem[];
	noseSillage?: Sillage;
	noseWeather?: FragranceNoseWeatherItem[];
	occasion: FragranceOccasion[];
	olfactiveId?: FragranceOlfactiveId;
	perfumers: FragrancePerfumersItem[];
	photoUpdateAt?: Date;
	preliminaryPhotos: FragrancePhoto[];
	privatePhotos: FragrancePhoto[];
	publicPhotos: FragrancePhoto[];
	season: FragranceSeason[];
	sillage?: Sillage;
	superId?: FragranceSuperId;
	superName?: string;
	superSetAt?: Date;
	superSetBy?: string;
	synonyms?: string[];
	weather: FragranceWeather[];
	years?: number[];
}

export type FragranceLinkId = (typeof FragranceLinkId)[keyof typeof FragranceLinkId];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const FragranceLinkId = {
	NUMBER_61: 61,
} as const;

export type FragranceLinkType = (typeof FragranceLinkType)[keyof typeof FragranceLinkType];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const FragranceLinkType = {
	ARTICLE: 'ARTICLE',
	VIDEO: 'VIDEO',
	INTERNAL: 'INTERNAL',
} as const;

export interface FragranceLink {
	id: FragranceLinkId;
	subtitle: string;
	title: string;
	type: FragranceLinkType;
	url: string;
}

export type FragranceClaspedId = (typeof FragranceClaspedId)[keyof typeof FragranceClaspedId];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const FragranceClaspedId = {
	NUMBER_43: 43,
} as const;

export interface FragranceClasped {
	id: FragranceClaspedId;
	name: string;
}

export type FragrancePhotoId = (typeof FragrancePhotoId)[keyof typeof FragrancePhotoId];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const FragrancePhotoId = {
	NUMBER_59: 59,
} as const;

export type FragrancePhotoComplainedById =
	(typeof FragrancePhotoComplainedById)[keyof typeof FragrancePhotoComplainedById];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const FragrancePhotoComplainedById = {
	NUMBER_48: 48,
} as const;

export type FragrancePhotoAuthorId = (typeof FragrancePhotoAuthorId)[keyof typeof FragrancePhotoAuthorId];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const FragrancePhotoAuthorId = {
	NUMBER_48: 48,
} as const;

export type ComplaintType = (typeof ComplaintType)[keyof typeof ComplaintType];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const ComplaintType = {
	SPAM: 'SPAM',
	NUDITY: 'NUDITY',
	HATE: 'HATE',
	ILLEGAL: 'ILLEGAL',
	FALSE: 'FALSE',
	BULLING: 'BULLING',
	SCAM: 'SCAM',
	VIOLENCE: 'VIOLENCE',
	PROPERTY: 'PROPERTY',
	OTHER: 'OTHER',
	ADS: 'ADS',
	INAPPROPRIATE: 'INAPPROPRIATE',
	NOTMATCH: 'NOTMATCH',
} as const;

export interface FragrancePhoto {
	authorId?: FragrancePhotoAuthorId;
	authorName?: string;
	authorUser?: string;
	caption?: string;
	complainedById?: FragrancePhotoComplainedById;
	complainedByUser?: string;
	complaintStatus?: ComplaintType;
	fileId: string;
	id: FragrancePhotoId;
	url: string;
}

export interface FragranceList {
	items: FragranceItem[];
	time?: number;
	total: number;
}

export type FragranceItemSuperId = (typeof FragranceItemSuperId)[keyof typeof FragranceItemSuperId];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const FragranceItemSuperId = {
	NUMBER_43: 43,
} as const;

export type FragranceItemOlfactiveId = (typeof FragranceItemOlfactiveId)[keyof typeof FragranceItemOlfactiveId];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const FragranceItemOlfactiveId = {
	NUMBER_45: 45,
} as const;

export type FragranceItemId = (typeof FragranceItemId)[keyof typeof FragranceItemId];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const FragranceItemId = {
	NUMBER_43: 43,
} as const;

export type FragranceItemBrandId = (typeof FragranceItemBrandId)[keyof typeof FragranceItemBrandId];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const FragranceItemBrandId = {
	NUMBER_42: 42,
} as const;

export type Concentration = (typeof Concentration)[keyof typeof Concentration];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const Concentration = {
	OTHER: 'OTHER',
	EAU_DE_TOILETTE: 'EAU_DE_TOILETTE',
	EAU_DE_PARFUM: 'EAU_DE_PARFUM',
	PERFUME: 'PERFUME',
	EAU_DE_COLOGNE: 'EAU_DE_COLOGNE',
	BODY_MIST: 'BODY_MIST',
	HAIR_MIST: 'HAIR_MIST',
	SOLID_PERFUME: 'SOLID_PERFUME',
	OIL_PERFUME: 'OIL_PERFUME',
} as const;

export type Longevity = (typeof Longevity)[keyof typeof Longevity];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const Longevity = {
	LESS_THAN_HOUR: 'LESS_THAN_HOUR',
	ABOUT_1_HOUR: 'ABOUT_1_HOUR',
	ABOUT_5_HOURS: 'ABOUT_5_HOURS',
	ABOUT_10_HOURS: 'ABOUT_10_HOURS',
	MORE_12_HOURS: 'MORE_12_HOURS',
} as const;

export type Sillage = (typeof Sillage)[keyof typeof Sillage];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const Sillage = {
	CLOSE_TO_SKIN: 'CLOSE_TO_SKIN',
	ARM_LENGTH: 'ARM_LENGTH',
	FEW_METERS: 'FEW_METERS',
	FILLS_ROOM: 'FILLS_ROOM',
} as const;

export type Gender = (typeof Gender)[keyof typeof Gender];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const Gender = {
	MALE: 'MALE',
	FEMALE: 'FEMALE',
	UNISEX: 'UNISEX',
} as const;

export interface FragranceItem {
	active: boolean;
	brandId: FragranceItemBrandId;
	brandName: string;
	concentration?: Concentration;
	description?: string;
	discontinued: boolean;
	gender?: Gender;
	id: FragranceItemId;
	longevity?: Longevity;
	name: string;
	olfactiveId?: FragranceItemOlfactiveId;
	olfactiveName?: string;
	perfumerNames?: string[];
	photo?: string;
	photoUpdateAt?: Date;
	relevance?: string;
	sillage?: Sillage;
	superId?: FragranceItemSuperId;
	superName?: string;
	superSetAt?: Date;
	years?: number[];
}

export interface PerfumerUpdateDto {
	active?: boolean;
	comment?: string;
	company?: string;
	description?: string;
	images?: string[];
	name: string;
}

export type PerfumerCreateDtoId = (typeof PerfumerCreateDtoId)[keyof typeof PerfumerCreateDtoId];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const PerfumerCreateDtoId = {
	NUMBER_46: 46,
} as const;

export interface PerfumerCreateDto {
	active?: boolean;
	comment?: string;
	company?: string;
	description?: string;
	id: PerfumerCreateDtoId;
	images?: string[];
	name: string;
}

export type PerfumerNameId = (typeof PerfumerNameId)[keyof typeof PerfumerNameId];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const PerfumerNameId = {
	NUMBER_46: 46,
} as const;

export interface PerfumerName {
	active: boolean;
	id: PerfumerNameId;
	name: string;
}

export interface PerfumerNames {
	items: PerfumerName[];
}

export type PerfumerId = (typeof PerfumerId)[keyof typeof PerfumerId];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const PerfumerId = {
	NUMBER_46: 46,
} as const;

export interface Perfumer {
	active: boolean;
	company?: string;
	description?: string;
	id: PerfumerId;
	images?: string[];
	name: string;
}

export interface PerfumerList {
	items: Perfumer[];
	total: number;
}

export interface NoteUpdateDto {
	active?: boolean;
	comment?: string;
	images?: string[];
	name: string;
	synonyms: string[];
}

export type NoteCreateDtoId = (typeof NoteCreateDtoId)[keyof typeof NoteCreateDtoId];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const NoteCreateDtoId = {
	NUMBER_44: 44,
} as const;

export interface NoteCreateDto {
	active?: boolean;
	comment?: string;
	id: NoteCreateDtoId;
	images?: string[];
	name: string;
	synonyms: string[];
}

export type NoteId = (typeof NoteId)[keyof typeof NoteId];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const NoteId = {
	NUMBER_44: 44,
} as const;

export interface Note {
	active: boolean;
	id: NoteId;
	images?: string[];
	name: string;
	synonyms: string[];
}

export type NoteNameId = (typeof NoteNameId)[keyof typeof NoteNameId];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const NoteNameId = {
	NUMBER_44: 44,
} as const;

export interface NoteName {
	active: boolean;
	id: NoteNameId;
	name: string;
}

export interface NoteNames {
	items: NoteName[];
}

export type NoteItemId = (typeof NoteItemId)[keyof typeof NoteItemId];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const NoteItemId = {
	NUMBER_44: 44,
} as const;

export interface NoteItem {
	active: boolean;
	id: NoteItemId;
	image?: string;
	name: string;
	synonyms: string[];
}

export interface NoteList {
	items: NoteItem[];
	total: number;
}

export interface ImportResult {
	imported: number;
	inserted: number;
	skipped: number;
	updated: number;
}

export type BrandImportItemId = (typeof BrandImportItemId)[keyof typeof BrandImportItemId];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const BrandImportItemId = {
	NUMBER_42: 42,
} as const;

export interface BrandImportItem {
	id: BrandImportItemId;
}

export interface BrandImport {
	fileName: string;
	items: BrandImportItem[];
}

export interface BrandUpdateDto {
	active?: boolean | null;
	comment?: string;
	country?: string;
	description?: string;
	images?: string[];
	name: string;
	synonyms?: string[];
	year?: number;
}

export type BrandCreateDtoId = (typeof BrandCreateDtoId)[keyof typeof BrandCreateDtoId];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const BrandCreateDtoId = {
	NUMBER_42: 42,
} as const;

export interface BrandCreateDto {
	active?: boolean | null;
	comment?: string;
	country?: string;
	description?: string;
	id: BrandCreateDtoId;
	images?: string[];
	name: string;
	synonyms?: string[];
	year?: number;
}

export interface BrandChangeActiveDto {
	active: boolean;
}

export type BrandNameId = (typeof BrandNameId)[keyof typeof BrandNameId];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const BrandNameId = {
	NUMBER_42: 42,
} as const;

export interface BrandName {
	active: boolean;
	id: BrandNameId;
	name: string;
}

export interface BrandNames {
	items: BrandName[];
}

export type BrandId = (typeof BrandId)[keyof typeof BrandId];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const BrandId = {
	NUMBER_42: 42,
} as const;

export interface Brand {
	active: boolean;
	country?: string;
	createdAt: Date;
	description?: string;
	id: BrandId;
	images?: string[];
	name: string;
	relevance?: string;
	synonyms?: string[];
	updatedAt: Date;
	year?: number;
}

export interface BrandList {
	items: Brand[];
	time?: number;
	total: number;
}

export type FTSStrategy = (typeof FTSStrategy)[keyof typeof FTSStrategy];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const FTSStrategy = {
	FTS_V1: 'FTS_V1',
	FTS_V2: 'FTS_V2',
	FTS_V3: 'FTS_V3',
	FTS_V4: 'FTS_V4',
	FTS_V5: 'FTS_V5',
} as const;

export type SortDir = (typeof SortDir)[keyof typeof SortDir];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const SortDir = {
	ASC: 'ASC',
	DESC: 'DESC',
} as const;

export type StaffUpdateDtoUserPermissionItem =
	(typeof StaffUpdateDtoUserPermissionItem)[keyof typeof StaffUpdateDtoUserPermissionItem];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const StaffUpdateDtoUserPermissionItem = {
	CREATE: 'CREATE',
	READ: 'READ',
	UPDATE: 'UPDATE',
	DELETE: 'DELETE',
	EXPORT: 'EXPORT',
	EMAIL: 'EMAIL',
} as const;

export type StaffUpdateDtoStaffPermissionItem =
	(typeof StaffUpdateDtoStaffPermissionItem)[keyof typeof StaffUpdateDtoStaffPermissionItem];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const StaffUpdateDtoStaffPermissionItem = {
	CREATE: 'CREATE',
	READ: 'READ',
	UPDATE: 'UPDATE',
	DELETE: 'DELETE',
	EXPORT: 'EXPORT',
} as const;

export type StaffUpdateDtoSnapshotPermissionItem =
	(typeof StaffUpdateDtoSnapshotPermissionItem)[keyof typeof StaffUpdateDtoSnapshotPermissionItem];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const StaffUpdateDtoSnapshotPermissionItem = {
	CREATE: 'CREATE',
	READ: 'READ',
	UPDATE: 'UPDATE',
	DELETE: 'DELETE',
	EXPORT: 'EXPORT',
} as const;

export type StaffUpdateDtoSettingsPermissionItem =
	(typeof StaffUpdateDtoSettingsPermissionItem)[keyof typeof StaffUpdateDtoSettingsPermissionItem];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const StaffUpdateDtoSettingsPermissionItem = {
	CREATE: 'CREATE',
	READ: 'READ',
	UPDATE: 'UPDATE',
	DELETE: 'DELETE',
	EXPORT: 'EXPORT',
} as const;

export type StaffUpdateDtoPushPermissionItem =
	(typeof StaffUpdateDtoPushPermissionItem)[keyof typeof StaffUpdateDtoPushPermissionItem];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const StaffUpdateDtoPushPermissionItem = {
	CREATE: 'CREATE',
	READ: 'READ',
	UPDATE: 'UPDATE',
	DELETE: 'DELETE',
	EXPORT: 'EXPORT',
} as const;

export type StaffUpdateDtoPostPermissionItem =
	(typeof StaffUpdateDtoPostPermissionItem)[keyof typeof StaffUpdateDtoPostPermissionItem];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const StaffUpdateDtoPostPermissionItem = {
	CREATE: 'CREATE',
	READ: 'READ',
	UPDATE: 'UPDATE',
	DELETE: 'DELETE',
	EXPORT: 'EXPORT',
} as const;

export type StaffUpdateDtoPerfumersPermissionItem =
	(typeof StaffUpdateDtoPerfumersPermissionItem)[keyof typeof StaffUpdateDtoPerfumersPermissionItem];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const StaffUpdateDtoPerfumersPermissionItem = {
	CREATE: 'CREATE',
	READ: 'READ',
	UPDATE: 'UPDATE',
	DELETE: 'DELETE',
	EXPORT: 'EXPORT',
} as const;

export type StaffUpdateDtoNotesPermissionItem =
	(typeof StaffUpdateDtoNotesPermissionItem)[keyof typeof StaffUpdateDtoNotesPermissionItem];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const StaffUpdateDtoNotesPermissionItem = {
	CREATE: 'CREATE',
	READ: 'READ',
	UPDATE: 'UPDATE',
	DELETE: 'DELETE',
	EXPORT: 'EXPORT',
} as const;

export type StaffUpdateDtoFragrancePermissionItem =
	(typeof StaffUpdateDtoFragrancePermissionItem)[keyof typeof StaffUpdateDtoFragrancePermissionItem];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const StaffUpdateDtoFragrancePermissionItem = {
	CREATE: 'CREATE',
	READ: 'READ',
	UPDATE: 'UPDATE',
	DELETE: 'DELETE',
	EXPORT: 'EXPORT',
} as const;

export type StaffUpdateDtoFeedbackPermissionItem =
	(typeof StaffUpdateDtoFeedbackPermissionItem)[keyof typeof StaffUpdateDtoFeedbackPermissionItem];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const StaffUpdateDtoFeedbackPermissionItem = {
	CREATE: 'CREATE',
	READ: 'READ',
	UPDATE: 'UPDATE',
	DELETE: 'DELETE',
	EXPORT: 'EXPORT',
} as const;

export type StaffUpdateDtoBrandPermissionItem =
	(typeof StaffUpdateDtoBrandPermissionItem)[keyof typeof StaffUpdateDtoBrandPermissionItem];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const StaffUpdateDtoBrandPermissionItem = {
	CREATE: 'CREATE',
	READ: 'READ',
	UPDATE: 'UPDATE',
	DELETE: 'DELETE',
	EXPORT: 'EXPORT',
} as const;

export interface StaffUpdateDto {
	brandPermission: StaffUpdateDtoBrandPermissionItem[];
	expiresAt?: Date;
	feedbackPermission: StaffUpdateDtoFeedbackPermissionItem[];
	fragrancePermission: StaffUpdateDtoFragrancePermissionItem[];
	name?: string;
	notesPermission: StaffUpdateDtoNotesPermissionItem[];
	perfumersPermission: StaffUpdateDtoPerfumersPermissionItem[];
	postPermission: StaffUpdateDtoPostPermissionItem[];
	pushPermission: StaffUpdateDtoPushPermissionItem[];
	settingsPermission: StaffUpdateDtoSettingsPermissionItem[];
	snapshotPermission: StaffUpdateDtoSnapshotPermissionItem[];
	staffPermission: StaffUpdateDtoStaffPermissionItem[];
	status: StaffStatus;
	userPermission: StaffUpdateDtoUserPermissionItem[];
}

export interface StaffChangeStatusDto {
	status: StaffStatus;
}

export interface StaffList {
	items: Staff[];
	total: number;
}

export type StaffUserPermissionItem = (typeof StaffUserPermissionItem)[keyof typeof StaffUserPermissionItem];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const StaffUserPermissionItem = {
	CREATE: 'CREATE',
	READ: 'READ',
	UPDATE: 'UPDATE',
	DELETE: 'DELETE',
	EXPORT: 'EXPORT',
	EMAIL: 'EMAIL',
} as const;

export type StaffStaffPermissionItem = (typeof StaffStaffPermissionItem)[keyof typeof StaffStaffPermissionItem];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const StaffStaffPermissionItem = {
	CREATE: 'CREATE',
	READ: 'READ',
	UPDATE: 'UPDATE',
	DELETE: 'DELETE',
	EXPORT: 'EXPORT',
} as const;

export type StaffSnapshotPermissionItem =
	(typeof StaffSnapshotPermissionItem)[keyof typeof StaffSnapshotPermissionItem];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const StaffSnapshotPermissionItem = {
	CREATE: 'CREATE',
	READ: 'READ',
	UPDATE: 'UPDATE',
	DELETE: 'DELETE',
	EXPORT: 'EXPORT',
} as const;

export type StaffSettingsPermissionItem =
	(typeof StaffSettingsPermissionItem)[keyof typeof StaffSettingsPermissionItem];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const StaffSettingsPermissionItem = {
	CREATE: 'CREATE',
	READ: 'READ',
	UPDATE: 'UPDATE',
	DELETE: 'DELETE',
	EXPORT: 'EXPORT',
} as const;

export type StaffPushPermissionItem = (typeof StaffPushPermissionItem)[keyof typeof StaffPushPermissionItem];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const StaffPushPermissionItem = {
	CREATE: 'CREATE',
	READ: 'READ',
	UPDATE: 'UPDATE',
	DELETE: 'DELETE',
	EXPORT: 'EXPORT',
} as const;

export type StaffPostPermissionItem = (typeof StaffPostPermissionItem)[keyof typeof StaffPostPermissionItem];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const StaffPostPermissionItem = {
	CREATE: 'CREATE',
	READ: 'READ',
	UPDATE: 'UPDATE',
	DELETE: 'DELETE',
	EXPORT: 'EXPORT',
} as const;

export type StaffPerfumersPermissionItem =
	(typeof StaffPerfumersPermissionItem)[keyof typeof StaffPerfumersPermissionItem];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const StaffPerfumersPermissionItem = {
	CREATE: 'CREATE',
	READ: 'READ',
	UPDATE: 'UPDATE',
	DELETE: 'DELETE',
	EXPORT: 'EXPORT',
} as const;

export type StaffNotesPermissionItem = (typeof StaffNotesPermissionItem)[keyof typeof StaffNotesPermissionItem];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const StaffNotesPermissionItem = {
	CREATE: 'CREATE',
	READ: 'READ',
	UPDATE: 'UPDATE',
	DELETE: 'DELETE',
	EXPORT: 'EXPORT',
} as const;

export type StaffId = (typeof StaffId)[keyof typeof StaffId];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const StaffId = {
	NUMBER_47: 47,
} as const;

export type StaffFragrancePermissionItem =
	(typeof StaffFragrancePermissionItem)[keyof typeof StaffFragrancePermissionItem];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const StaffFragrancePermissionItem = {
	CREATE: 'CREATE',
	READ: 'READ',
	UPDATE: 'UPDATE',
	DELETE: 'DELETE',
	EXPORT: 'EXPORT',
} as const;

export type StaffFeedbackPermissionItem =
	(typeof StaffFeedbackPermissionItem)[keyof typeof StaffFeedbackPermissionItem];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const StaffFeedbackPermissionItem = {
	CREATE: 'CREATE',
	READ: 'READ',
	UPDATE: 'UPDATE',
	DELETE: 'DELETE',
	EXPORT: 'EXPORT',
} as const;

export type StaffBrandPermissionItem = (typeof StaffBrandPermissionItem)[keyof typeof StaffBrandPermissionItem];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const StaffBrandPermissionItem = {
	CREATE: 'CREATE',
	READ: 'READ',
	UPDATE: 'UPDATE',
	DELETE: 'DELETE',
	EXPORT: 'EXPORT',
} as const;

export interface Staff {
	avatar?: string;
	brandPermission: StaffBrandPermissionItem[];
	createdAt: Date;
	email: string;
	expiresAt?: Date;
	feedbackPermission: StaffFeedbackPermissionItem[];
	fragrancePermission: StaffFragrancePermissionItem[];
	id: StaffId;
	lastSeenAt: Date;
	name?: string;
	notesPermission: StaffNotesPermissionItem[];
	perfumersPermission: StaffPerfumersPermissionItem[];
	postPermission: StaffPostPermissionItem[];
	pushPermission: StaffPushPermissionItem[];
	settingsPermission: StaffSettingsPermissionItem[];
	snapshotPermission: StaffSnapshotPermissionItem[];
	staffPermission: StaffStaffPermissionItem[];
	status: StaffStatus;
	updatedAt: Date;
	userPermission: StaffUserPermissionItem[];
}

export interface SignInResponse {
	staff: StaffMe;
	token: string;
}

export interface SignInWithGoogleDto {
	token: string;
}

export type StaffMeUserPermissionItem = (typeof StaffMeUserPermissionItem)[keyof typeof StaffMeUserPermissionItem];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const StaffMeUserPermissionItem = {
	CREATE: 'CREATE',
	READ: 'READ',
	UPDATE: 'UPDATE',
	DELETE: 'DELETE',
	EXPORT: 'EXPORT',
	EMAIL: 'EMAIL',
} as const;

export type StaffMeStaffPermissionItem = (typeof StaffMeStaffPermissionItem)[keyof typeof StaffMeStaffPermissionItem];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const StaffMeStaffPermissionItem = {
	CREATE: 'CREATE',
	READ: 'READ',
	UPDATE: 'UPDATE',
	DELETE: 'DELETE',
	EXPORT: 'EXPORT',
} as const;

export type StaffMeSnapshotPermissionItem =
	(typeof StaffMeSnapshotPermissionItem)[keyof typeof StaffMeSnapshotPermissionItem];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const StaffMeSnapshotPermissionItem = {
	CREATE: 'CREATE',
	READ: 'READ',
	UPDATE: 'UPDATE',
	DELETE: 'DELETE',
	EXPORT: 'EXPORT',
} as const;

export type StaffMeSettingsPermissionItem =
	(typeof StaffMeSettingsPermissionItem)[keyof typeof StaffMeSettingsPermissionItem];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const StaffMeSettingsPermissionItem = {
	CREATE: 'CREATE',
	READ: 'READ',
	UPDATE: 'UPDATE',
	DELETE: 'DELETE',
	EXPORT: 'EXPORT',
} as const;

export type StaffMePushPermissionItem = (typeof StaffMePushPermissionItem)[keyof typeof StaffMePushPermissionItem];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const StaffMePushPermissionItem = {
	CREATE: 'CREATE',
	READ: 'READ',
	UPDATE: 'UPDATE',
	DELETE: 'DELETE',
	EXPORT: 'EXPORT',
} as const;

export type StaffMePostPermissionItem = (typeof StaffMePostPermissionItem)[keyof typeof StaffMePostPermissionItem];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const StaffMePostPermissionItem = {
	CREATE: 'CREATE',
	READ: 'READ',
	UPDATE: 'UPDATE',
	DELETE: 'DELETE',
	EXPORT: 'EXPORT',
} as const;

export type StaffMePerfumersPermissionItem =
	(typeof StaffMePerfumersPermissionItem)[keyof typeof StaffMePerfumersPermissionItem];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const StaffMePerfumersPermissionItem = {
	CREATE: 'CREATE',
	READ: 'READ',
	UPDATE: 'UPDATE',
	DELETE: 'DELETE',
	EXPORT: 'EXPORT',
} as const;

export type StaffMeNotesPermissionItem = (typeof StaffMeNotesPermissionItem)[keyof typeof StaffMeNotesPermissionItem];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const StaffMeNotesPermissionItem = {
	CREATE: 'CREATE',
	READ: 'READ',
	UPDATE: 'UPDATE',
	DELETE: 'DELETE',
	EXPORT: 'EXPORT',
} as const;

export type StaffMeId = (typeof StaffMeId)[keyof typeof StaffMeId];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const StaffMeId = {
	NUMBER_47: 47,
} as const;

export type StaffMeFragrancePermissionItem =
	(typeof StaffMeFragrancePermissionItem)[keyof typeof StaffMeFragrancePermissionItem];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const StaffMeFragrancePermissionItem = {
	CREATE: 'CREATE',
	READ: 'READ',
	UPDATE: 'UPDATE',
	DELETE: 'DELETE',
	EXPORT: 'EXPORT',
} as const;

export type StaffMeFeedbackPermissionItem =
	(typeof StaffMeFeedbackPermissionItem)[keyof typeof StaffMeFeedbackPermissionItem];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const StaffMeFeedbackPermissionItem = {
	CREATE: 'CREATE',
	READ: 'READ',
	UPDATE: 'UPDATE',
	DELETE: 'DELETE',
	EXPORT: 'EXPORT',
} as const;

export type StaffMeBrandPermissionItem = (typeof StaffMeBrandPermissionItem)[keyof typeof StaffMeBrandPermissionItem];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const StaffMeBrandPermissionItem = {
	CREATE: 'CREATE',
	READ: 'READ',
	UPDATE: 'UPDATE',
	DELETE: 'DELETE',
	EXPORT: 'EXPORT',
} as const;

export type StaffStatus = (typeof StaffStatus)[keyof typeof StaffStatus];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const StaffStatus = {
	NEW: 'NEW',
	BAN: 'BAN',
	OK: 'OK',
} as const;

export interface StaffMe {
	avatar?: string;
	brandPermission: StaffMeBrandPermissionItem[];
	createdAt: Date;
	email: string;
	feedbackPermission: StaffMeFeedbackPermissionItem[];
	fragrancePermission: StaffMeFragrancePermissionItem[];
	id: StaffMeId;
	name?: string;
	notesPermission: StaffMeNotesPermissionItem[];
	perfumersPermission: StaffMePerfumersPermissionItem[];
	postPermission: StaffMePostPermissionItem[];
	pushPermission: StaffMePushPermissionItem[];
	settingsPermission: StaffMeSettingsPermissionItem[];
	snapshotPermission: StaffMeSnapshotPermissionItem[];
	staffPermission: StaffMeStaffPermissionItem[];
	status: StaffStatus;
	updatedAt: Date;
	userPermission: StaffMeUserPermissionItem[];
}

export const getAromoshelfAdminAPI = () => {
	const authMe = () => {
		return getAxios<StaffMe>({ url: `/auth/me`, method: 'GET' });
	};

	const authSignInWithGoogle = (signInWithGoogleDto: SignInWithGoogleDto) => {
		return getAxios<SignInResponse>({
			url: `/auth/google`,
			method: 'POST',
			headers: { 'Content-Type': 'application/json' },
			data: signInWithGoogleDto,
		});
	};

	/**
	 * Get all staff list
	 */
	const staffGetAll = () => {
		return getAxios<StaffList>({ url: `/staff`, method: 'GET' });
	};

	/**
	 * Get staff by id
	 */
	const staffGetOne = (id: number) => {
		return getAxios<Staff>({ url: `/staff/${id}`, method: 'GET' });
	};

	/**
	 * Update staff
	 */
	const staffUpdate = (id: number, staffUpdateDto: StaffUpdateDto) => {
		return getAxios<void>({
			url: `/staff/${id}`,
			method: 'PATCH',
			headers: { 'Content-Type': 'application/json' },
			data: staffUpdateDto,
		});
	};

	/**
	 * Delete staff
	 */
	const staffDelete = (id: number) => {
		return getAxios<void>({ url: `/staff/${id}`, method: 'DELETE' });
	};

	/**
	 * Change staff status
	 */
	const staffChangeStatus = (id: number, staffChangeStatusDto: StaffChangeStatusDto) => {
		return getAxios<void>({
			url: `/staff/${id}/status`,
			method: 'PATCH',
			headers: { 'Content-Type': 'application/json' },
			data: staffChangeStatusDto,
		});
	};

	const brandGetAll = (params?: BrandGetAllParams) => {
		return getAxios<BrandList>({ url: `/brand`, method: 'GET', params });
	};

	/**
	 * Create brand
	 */
	const brandCreate = (brandCreateDto: BrandCreateDto) => {
		return getAxios<void>({
			url: `/brand`,
			method: 'POST',
			headers: { 'Content-Type': 'application/json' },
			data: brandCreateDto,
		});
	};

	const brandGetNames = () => {
		return getAxios<BrandNames>({ url: `/brand/names`, method: 'GET' });
	};

	/**
	 * Get brand by id
	 */
	const brandGetOne = (id: number) => {
		return getAxios<Brand>({ url: `/brand/${id}`, method: 'GET' });
	};

	/**
	 * Update brand
	 */
	const brandUpdate = (id: number, brandUpdateDto: BrandUpdateDto) => {
		return getAxios<void>({
			url: `/brand/${id}`,
			method: 'PATCH',
			headers: { 'Content-Type': 'application/json' },
			data: brandUpdateDto,
		});
	};

	/**
	 * Delete brand
	 */
	const brandDelete = (id: number) => {
		return getAxios<void>({ url: `/brand/${id}`, method: 'DELETE' });
	};

	/**
	 * Change brand active state
	 */
	const brandChangeStatus = (id: number, brandChangeActiveDto: BrandChangeActiveDto) => {
		return getAxios<void>({
			url: `/brand/${id}/active`,
			method: 'PATCH',
			headers: { 'Content-Type': 'application/json' },
			data: brandChangeActiveDto,
		});
	};

	/**
	 * Import brands
	 */
	const brandImport = (brandImport: BrandImport) => {
		return getAxios<ImportResult>({
			url: `/brand/import`,
			method: 'POST',
			headers: { 'Content-Type': 'application/json' },
			data: brandImport,
		});
	};

	const noteGetAll = (params?: NoteGetAllParams) => {
		return getAxios<NoteList>({ url: `/note`, method: 'GET', params });
	};

	/**
	 * Create note
	 */
	const noteCreate = (noteCreateDto: NoteCreateDto) => {
		return getAxios<void>({
			url: `/note`,
			method: 'POST',
			headers: { 'Content-Type': 'application/json' },
			data: noteCreateDto,
		});
	};

	const noteGetNames = () => {
		return getAxios<NoteNames>({ url: `/note/names`, method: 'GET' });
	};

	/**
	 * Get note by id
	 */
	const noteGetOne = (id: number) => {
		return getAxios<Note>({ url: `/note/${id}`, method: 'GET' });
	};

	/**
	 * Update note
	 */
	const noteUpdate = (id: number, noteUpdateDto: NoteUpdateDto) => {
		return getAxios<void>({
			url: `/note/${id}`,
			method: 'PATCH',
			headers: { 'Content-Type': 'application/json' },
			data: noteUpdateDto,
		});
	};

	/**
	 * Delete note
	 */
	const noteDelete = (id: number) => {
		return getAxios<void>({ url: `/note/${id}`, method: 'DELETE' });
	};

	/**
	 * Get all perfumers
	 */
	const perfumerGetAll = (params?: PerfumerGetAllParams) => {
		return getAxios<PerfumerList>({ url: `/perfumer`, method: 'GET', params });
	};

	/**
	 * Create perfumer
	 */
	const perfumerCreate = (perfumerCreateDto: PerfumerCreateDto) => {
		return getAxios<void>({
			url: `/perfumer`,
			method: 'POST',
			headers: { 'Content-Type': 'application/json' },
			data: perfumerCreateDto,
		});
	};

	const perfumerGetNames = () => {
		return getAxios<PerfumerNames>({ url: `/perfumer/names`, method: 'GET' });
	};

	/**
	 * Get perfumer by id
	 */
	const perfumerGetOne = (id: number) => {
		return getAxios<Perfumer>({ url: `/perfumer/${id}`, method: 'GET' });
	};

	/**
	 * Update perfumer
	 */
	const perfumerUpdate = (id: number, perfumerUpdateDto: PerfumerUpdateDto) => {
		return getAxios<void>({
			url: `/perfumer/${id}`,
			method: 'PATCH',
			headers: { 'Content-Type': 'application/json' },
			data: perfumerUpdateDto,
		});
	};

	/**
	 * Delete perfumer
	 */
	const perfumerDelete = (id: number) => {
		return getAxios<void>({ url: `/perfumer/${id}`, method: 'DELETE' });
	};

	const fragranceGetAll = (params?: FragranceGetAllParams) => {
		return getAxios<FragranceList>({ url: `/fragrance`, method: 'GET', params });
	};

	/**
	 * Create fragrance
	 */
	const fragranceCreate = (fragranceCreateDto: FragranceCreateDto) => {
		return getAxios<void>({
			url: `/fragrance`,
			method: 'POST',
			headers: { 'Content-Type': 'application/json' },
			data: fragranceCreateDto,
		});
	};

	/**
	 * Get fragrance by id
	 */
	const fragranceGetOne = (id: number) => {
		return getAxios<Fragrance>({ url: `/fragrance/${id}`, method: 'GET' });
	};

	/**
	 * Update fragrance
	 */
	const fragranceUpdate = (id: number, fragranceUpdateDto: FragranceUpdateDto) => {
		return getAxios<void>({
			url: `/fragrance/${id}`,
			method: 'PATCH',
			headers: { 'Content-Type': 'application/json' },
			data: fragranceUpdateDto,
		});
	};

	/**
	 * Delete fragrance
	 */
	const fragranceDelete = (id: number) => {
		return getAxios<void>({ url: `/fragrance/${id}`, method: 'DELETE' });
	};

	/**
	 * Change user photo complaint
	 */
	const fragranceChangePhotoComplaint = (
		id: number,
		fragranceChangePhotoComplaintDto: FragranceChangePhotoComplaintDto,
	) => {
		return getAxios<void>({
			url: `/fragrance/${id}/complaint/photo/change`,
			method: 'POST',
			headers: { 'Content-Type': 'application/json' },
			data: fragranceChangePhotoComplaintDto,
		});
	};

	/**
	 * Export user photos
	 */
	const fragranceExportImages = () => {
		return getAxios<CSVResponse>({ url: `/fragrance/export/images`, method: 'GET' });
	};

	/**
	 * Import fragrances
	 */
	const fragranceImport = (fragranceImport: FragranceImport) => {
		return getAxios<ImportResult>({
			url: `/fragrance/import`,
			method: 'POST',
			headers: { 'Content-Type': 'application/json' },
			data: fragranceImport,
		});
	};

	/**
	 * Import fragrances
	 */
	const fragranceSetSuperFragrance = (id: number, fragranceSetSuperDto: FragranceSetSuperDto) => {
		return getAxios<ImportResult>({
			url: `/fragrance/${id}/super`,
			method: 'POST',
			headers: { 'Content-Type': 'application/json' },
			data: fragranceSetSuperDto,
		});
	};

	/**
	 * Get fragrance url info
	 */
	const fragranceGetUrlTitle = (urlInfoDto: UrlInfoDto) => {
		return getAxios<UrlInfo>({
			url: `/fragrance/url-info`,
			method: 'POST',
			headers: { 'Content-Type': 'application/json' },
			data: urlInfoDto,
		});
	};

	/**
	 * Export fragrances
	 */
	const fragranceExportCSV = (params?: FragranceExportCSVParams) => {
		return getAxios<CSVResponse>({ url: `/fragrance/export/csv`, method: 'GET', params });
	};

	/**
	 * Get users have this fragrance on shelves
	 */
	const fragranceGetExperiences = (id: number, params?: FragranceGetExperiencesParams) => {
		return getAxios<FragranceExperienceList>({ url: `/fragrance/${id}/experiences`, method: 'GET', params });
	};

	/**
	 * Get users reviews for fragrance
	 */
	const fragranceGetReviews = (id: number, params?: FragranceGetReviewsParams) => {
		return getAxios<FragranceReviewList>({ url: `/fragrance/${id}/reviews`, method: 'GET', params });
	};

	/**
	 * Change review status for fragrance by user
	 */
	const fragranceChangeReviewStatus = (
		id: number,
		userId: number,
		fragranceReviewStatusDto: FragranceReviewStatusDto,
	) => {
		return getAxios<void>({
			url: `/fragrance/${id}/${userId}`,
			method: 'POST',
			headers: { 'Content-Type': 'application/json' },
			data: fragranceReviewStatusDto,
		});
	};

	/**
	 * Get all feedbacks
	 */
	const feedbackGetAll = (params?: FeedbackGetAllParams) => {
		return getAxios<FeedbackList>({ url: `/feedback`, method: 'GET', params });
	};

	/**
	 * Update feedback status
	 */
	const feedbackUpdateStatus = (id: number, feedbackChangeStatusDto: FeedbackChangeStatusDto) => {
		return getAxios<Feedback>({
			url: `/feedback/${id}/status`,
			method: 'POST',
			headers: { 'Content-Type': 'application/json' },
			data: feedbackChangeStatusDto,
		});
	};

	/**
	 * Reply to feedback
	 */
	const feedbackReply = (id: number, feedbackReplyDto: FeedbackReplyDto) => {
		return getAxios<Feedback>({
			url: `/feedback/${id}/reply`,
			method: 'POST',
			headers: { 'Content-Type': 'application/json' },
			data: feedbackReplyDto,
		});
	};

	const olfactiveGetNames = () => {
		return getAxios<OlfactiveNames>({ url: `/olfactive/names`, method: 'GET' });
	};

	/**
	 * Get upload urls
	 */
	const uploadGetUrls = (uploadUrlBody: UploadUrlBody) => {
		return getAxios<UploadUrlList>({
			url: `/upload/urls`,
			method: 'POST',
			headers: { 'Content-Type': 'application/json' },
			data: uploadUrlBody,
		});
	};

	const uploadPhoto = () => {
		return getAxios<UploadPhotoResponse>({ url: `/upload/photo`, method: 'PUT' });
	};

	const uploadImages = (fileUploadDto: FileUploadDto) => {
		const formData = new FormData();
		fileUploadDto.files.forEach(value => formData.append('files', value));

		return getAxios<UploadImagesResponse>({
			url: `/upload/images`,
			method: 'POST',
			headers: { 'Content-Type': 'multipart/form-data' },
			data: formData,
		});
	};

	const changeLogGetAll = (params?: ChangeLogGetAllParams) => {
		return getAxios<ChangeLogList>({ url: `/changelog`, method: 'GET', params });
	};

	/**
	 * Get all campaigns
	 */
	const pushCampaignGetAll = (params?: PushCampaignGetAllParams) => {
		return getAxios<PushCampaignList>({ url: `/push-campaign`, method: 'GET', params });
	};

	/**
	 * Create campaign
	 */
	const pushCampaignCreate = (pushCampaignCreateDto: PushCampaignCreateDto) => {
		return getAxios<void>({
			url: `/push-campaign`,
			method: 'POST',
			headers: { 'Content-Type': 'application/json' },
			data: pushCampaignCreateDto,
		});
	};

	/**
	 * Get campaign by id
	 */
	const pushCampaignGetOne = (id: number) => {
		return getAxios<PushCampaign>({ url: `/push-campaign/${id}`, method: 'GET' });
	};

	/**
	 * Update campaign
	 */
	const pushCampaignUpdate = (id: number, pushCampaignUpdateDto: PushCampaignUpdateDto) => {
		return getAxios<void>({
			url: `/push-campaign/${id}`,
			method: 'PATCH',
			headers: { 'Content-Type': 'application/json' },
			data: pushCampaignUpdateDto,
		});
	};

	/**
	 * Delete campaign
	 */
	const pushCampaignDelete = (id: number) => {
		return getAxios<void>({ url: `/push-campaign/${id}`, method: 'DELETE' });
	};

	/**
	 * Stop (cancel) campaign
	 */
	const pushCampaignStop = (id: number) => {
		return getAxios<void>({ url: `/push-campaign/${id}/stop`, method: 'POST' });
	};

	/**
	 * Get all snapshots
	 */
	const snapshotGetAll = (params?: SnapshotGetAllParams) => {
		return getAxios<SnapshotList>({ url: `/snapshot`, method: 'GET', params });
	};

	/**
	 * Create snapshot
	 */
	const snapshotCreate = () => {
		return getAxios<void>({ url: `/snapshot`, method: 'POST' });
	};

	/**
	 * Apply snapshot
	 */
	const snapshotRestore = (id: string) => {
		return getAxios<void>({ url: `/snapshot/${id}`, method: 'PATCH' });
	};

	/**
	 * Delete snapshot
	 */
	const snapshotDelete = (id: string) => {
		return getAxios<void>({ url: `/snapshot/${id}`, method: 'DELETE' });
	};

	/**
	 * Cancel restore
	 */
	const snapshotCancel = () => {
		return getAxios<void>({ url: `/snapshot/cancel`, method: 'POST' });
	};

	/**
	 * Get all ingredients
	 */
	const ingredientGetAll = (params?: IngredientGetAllParams) => {
		return getAxios<IngredientList>({ url: `/ingredient`, method: 'GET', params });
	};

	/**
	 * Create ingredient
	 */
	const ingredientCreate = (ingredientCreateDto: IngredientCreateDto) => {
		return getAxios<void>({
			url: `/ingredient`,
			method: 'POST',
			headers: { 'Content-Type': 'application/json' },
			data: ingredientCreateDto,
		});
	};

	/**
	 * Get ingredient names
	 */
	const ingredientGetNames = () => {
		return getAxios<IngredientNames>({ url: `/ingredient/names`, method: 'GET' });
	};

	/**
	 * Get ingredient by id
	 */
	const ingredientGetOne = (id: number) => {
		return getAxios<Ingredient>({ url: `/ingredient/${id}`, method: 'GET' });
	};

	/**
	 * Update ingredient
	 */
	const ingredientUpdate = (id: number, ingredientUpdateDto: IngredientUpdateDto) => {
		return getAxios<void>({
			url: `/ingredient/${id}`,
			method: 'PATCH',
			headers: { 'Content-Type': 'application/json' },
			data: ingredientUpdateDto,
		});
	};

	/**
	 * Delete ingredient
	 */
	const ingredientDelete = (id: number) => {
		return getAxios<void>({ url: `/ingredient/${id}`, method: 'DELETE' });
	};

	/**
	 * Get all users
	 */
	const userGetAll = (params?: UserGetAllParams) => {
		return getAxios<UserList>({ url: `/user`, method: 'GET', params });
	};

	/**
	 * Get user by id
	 */
	const userGetOne = (id: number) => {
		return getAxios<User>({ url: `/user/${id}`, method: 'GET' });
	};

	/**
	 * Delete user
	 */
	const userDelete = (id: number) => {
		return getAxios<void>({ url: `/user/${id}`, method: 'DELETE' });
	};

	/**
	 * Get user experiences
	 */
	const userGetExperiences = (id: number, params?: UserGetExperiencesParams) => {
		return getAxios<UserExperienceList>({ url: `/user/${id}/experiences`, method: 'GET', params });
	};

	/**
	 * Get user experience by id
	 */
	const userGetExperience = (id: number, expId: number) => {
		return getAxios<UserExperience>({ url: `/user/${id}/experiences/${expId}`, method: 'GET' });
	};

	/**
	 * Set user status
	 */
	const userSetStatus = (id: number, userChangeStatusDto: UserChangeStatusDto) => {
		return getAxios<void>({
			url: `/user/${id}/status`,
			method: 'POST',
			headers: { 'Content-Type': 'application/json' },
			data: userChangeStatusDto,
		});
	};

	/**
	 * Set user comment
	 */
	const userSetComment = (id: number, userChangeCommentDto: UserChangeCommentDto) => {
		return getAxios<void>({
			url: `/user/${id}/comment`,
			method: 'POST',
			headers: { 'Content-Type': 'application/json' },
			data: userChangeCommentDto,
		});
	};

	/**
	 * Set user role
	 */
	const userChangeRole = (id: number, userChangeRoleDto: UserChangeRoleDto) => {
		return getAxios<void>({
			url: `/user/${id}/role`,
			method: 'POST',
			headers: { 'Content-Type': 'application/json' },
			data: userChangeRoleDto,
		});
	};

	/**
	 * Get user posts
	 */
	const userGetPosts = (id: number, params?: UserGetPostsParams) => {
		return getAxios<UserPostList>({ url: `/user/${id}/post`, method: 'GET', params });
	};

	/**
	 * Send message to user
	 */
	const userSendMessage = (id: number, userMessageDto: UserMessageDto) => {
		return getAxios<void>({
			url: `/user/${id}/message`,
			method: 'POST',
			headers: { 'Content-Type': 'application/json' },
			data: userMessageDto,
		});
	};

	/**
	 * Get all posts
	 */
	const postGetAll = (params?: PostGetAllParams) => {
		return getAxios<PostList>({ url: `/post`, method: 'GET', params });
	};

	/**
	 * Create post
	 */
	const postCreate = (postCreateDto: PostCreateDto) => {
		return getAxios<void>({
			url: `/post`,
			method: 'POST',
			headers: { 'Content-Type': 'application/json' },
			data: postCreateDto,
		});
	};

	/**
	 * Get post by id
	 */
	const postGetOne = (id: number) => {
		return getAxios<Post>({ url: `/post/${id}`, method: 'GET' });
	};

	/**
	 * Update post
	 */
	const postUpdate = (id: number, postUpdateDto: PostUpdateDto) => {
		return getAxios<void>({
			url: `/post/${id}`,
			method: 'PATCH',
			headers: { 'Content-Type': 'application/json' },
			data: postUpdateDto,
		});
	};

	/**
	 * Delete a post
	 */
	const postDelete = (id: number) => {
		return getAxios<void>({ url: `/post/${id}`, method: 'DELETE' });
	};

	/**
	 * Change moderation status of a post
	 */
	const postModerate = (id: number, postModerationDto: PostModerationDto) => {
		return getAxios<void>({
			url: `/post/${id}/moderate`,
			method: 'POST',
			headers: { 'Content-Type': 'application/json' },
			data: postModerationDto,
		});
	};

	/**
	 * Get all stopwords
	 */
	const stopWordsGet = () => {
		return getAxios<StopWordList>({ url: `/stopwords`, method: 'GET' });
	};

	/**
	 * Create snapshot
	 */
	const stopWordsSave = (stopWordsDto: StopWordsDto) => {
		return getAxios<void>({
			url: `/stopwords`,
			method: 'POST',
			headers: { 'Content-Type': 'application/json' },
			data: stopWordsDto,
		});
	};

	/**
	 * Get recommends lists
	 */
	const recommendsGetLists = () => {
		return getAxios<RecommendsLists>({ url: `/recommends`, method: 'GET' });
	};

	/**
	 * Save recommends lists
	 */
	const recommendsSaveLists = (recommendsListsDto: RecommendsListsDto) => {
		return getAxios<void>({
			url: `/recommends`,
			method: 'POST',
			headers: { 'Content-Type': 'application/json' },
			data: recommendsListsDto,
		});
	};

	/**
	 * Get settings
	 */
	const settingsGet = () => {
		return getAxios<Settings>({ url: `/settings`, method: 'GET' });
	};

	/**
	 * Set settings
	 */
	const settingsSet = (settingsDto: SettingsDto) => {
		return getAxios<void>({
			url: `/settings`,
			method: 'POST',
			headers: { 'Content-Type': 'application/json' },
			data: settingsDto,
		});
	};

	/**
	 * Health check
	 */
	const adminHealthCheck = () => {
		return getAxios<void>({ url: `/healthcheck`, method: 'GET' });
	};

	return {
		authMe,
		authSignInWithGoogle,
		staffGetAll,
		staffGetOne,
		staffUpdate,
		staffDelete,
		staffChangeStatus,
		brandGetAll,
		brandCreate,
		brandGetNames,
		brandGetOne,
		brandUpdate,
		brandDelete,
		brandChangeStatus,
		brandImport,
		noteGetAll,
		noteCreate,
		noteGetNames,
		noteGetOne,
		noteUpdate,
		noteDelete,
		perfumerGetAll,
		perfumerCreate,
		perfumerGetNames,
		perfumerGetOne,
		perfumerUpdate,
		perfumerDelete,
		fragranceGetAll,
		fragranceCreate,
		fragranceGetOne,
		fragranceUpdate,
		fragranceDelete,
		fragranceChangePhotoComplaint,
		fragranceExportImages,
		fragranceImport,
		fragranceSetSuperFragrance,
		fragranceGetUrlTitle,
		fragranceExportCSV,
		fragranceGetExperiences,
		fragranceGetReviews,
		fragranceChangeReviewStatus,
		feedbackGetAll,
		feedbackUpdateStatus,
		feedbackReply,
		olfactiveGetNames,
		uploadGetUrls,
		uploadPhoto,
		uploadImages,
		changeLogGetAll,
		pushCampaignGetAll,
		pushCampaignCreate,
		pushCampaignGetOne,
		pushCampaignUpdate,
		pushCampaignDelete,
		pushCampaignStop,
		snapshotGetAll,
		snapshotCreate,
		snapshotRestore,
		snapshotDelete,
		snapshotCancel,
		ingredientGetAll,
		ingredientCreate,
		ingredientGetNames,
		ingredientGetOne,
		ingredientUpdate,
		ingredientDelete,
		userGetAll,
		userGetOne,
		userDelete,
		userGetExperiences,
		userGetExperience,
		userSetStatus,
		userSetComment,
		userChangeRole,
		userGetPosts,
		userSendMessage,
		postGetAll,
		postCreate,
		postGetOne,
		postUpdate,
		postDelete,
		postModerate,
		stopWordsGet,
		stopWordsSave,
		recommendsGetLists,
		recommendsSaveLists,
		settingsGet,
		settingsSet,
		adminHealthCheck,
	};
};
export type AuthMeResult = NonNullable<Awaited<ReturnType<ReturnType<typeof getAromoshelfAdminAPI>['authMe']>>>;
export type AuthSignInWithGoogleResult = NonNullable<
	Awaited<ReturnType<ReturnType<typeof getAromoshelfAdminAPI>['authSignInWithGoogle']>>
>;
export type StaffGetAllResult = NonNullable<
	Awaited<ReturnType<ReturnType<typeof getAromoshelfAdminAPI>['staffGetAll']>>
>;
export type StaffGetOneResult = NonNullable<
	Awaited<ReturnType<ReturnType<typeof getAromoshelfAdminAPI>['staffGetOne']>>
>;
export type StaffUpdateResult = NonNullable<
	Awaited<ReturnType<ReturnType<typeof getAromoshelfAdminAPI>['staffUpdate']>>
>;
export type StaffDeleteResult = NonNullable<
	Awaited<ReturnType<ReturnType<typeof getAromoshelfAdminAPI>['staffDelete']>>
>;
export type StaffChangeStatusResult = NonNullable<
	Awaited<ReturnType<ReturnType<typeof getAromoshelfAdminAPI>['staffChangeStatus']>>
>;
export type BrandGetAllResult = NonNullable<
	Awaited<ReturnType<ReturnType<typeof getAromoshelfAdminAPI>['brandGetAll']>>
>;
export type BrandCreateResult = NonNullable<
	Awaited<ReturnType<ReturnType<typeof getAromoshelfAdminAPI>['brandCreate']>>
>;
export type BrandGetNamesResult = NonNullable<
	Awaited<ReturnType<ReturnType<typeof getAromoshelfAdminAPI>['brandGetNames']>>
>;
export type BrandGetOneResult = NonNullable<
	Awaited<ReturnType<ReturnType<typeof getAromoshelfAdminAPI>['brandGetOne']>>
>;
export type BrandUpdateResult = NonNullable<
	Awaited<ReturnType<ReturnType<typeof getAromoshelfAdminAPI>['brandUpdate']>>
>;
export type BrandDeleteResult = NonNullable<
	Awaited<ReturnType<ReturnType<typeof getAromoshelfAdminAPI>['brandDelete']>>
>;
export type BrandChangeStatusResult = NonNullable<
	Awaited<ReturnType<ReturnType<typeof getAromoshelfAdminAPI>['brandChangeStatus']>>
>;
export type BrandImportResult = NonNullable<
	Awaited<ReturnType<ReturnType<typeof getAromoshelfAdminAPI>['brandImport']>>
>;
export type NoteGetAllResult = NonNullable<Awaited<ReturnType<ReturnType<typeof getAromoshelfAdminAPI>['noteGetAll']>>>;
export type NoteCreateResult = NonNullable<Awaited<ReturnType<ReturnType<typeof getAromoshelfAdminAPI>['noteCreate']>>>;
export type NoteGetNamesResult = NonNullable<
	Awaited<ReturnType<ReturnType<typeof getAromoshelfAdminAPI>['noteGetNames']>>
>;
export type NoteGetOneResult = NonNullable<Awaited<ReturnType<ReturnType<typeof getAromoshelfAdminAPI>['noteGetOne']>>>;
export type NoteUpdateResult = NonNullable<Awaited<ReturnType<ReturnType<typeof getAromoshelfAdminAPI>['noteUpdate']>>>;
export type NoteDeleteResult = NonNullable<Awaited<ReturnType<ReturnType<typeof getAromoshelfAdminAPI>['noteDelete']>>>;
export type PerfumerGetAllResult = NonNullable<
	Awaited<ReturnType<ReturnType<typeof getAromoshelfAdminAPI>['perfumerGetAll']>>
>;
export type PerfumerCreateResult = NonNullable<
	Awaited<ReturnType<ReturnType<typeof getAromoshelfAdminAPI>['perfumerCreate']>>
>;
export type PerfumerGetNamesResult = NonNullable<
	Awaited<ReturnType<ReturnType<typeof getAromoshelfAdminAPI>['perfumerGetNames']>>
>;
export type PerfumerGetOneResult = NonNullable<
	Awaited<ReturnType<ReturnType<typeof getAromoshelfAdminAPI>['perfumerGetOne']>>
>;
export type PerfumerUpdateResult = NonNullable<
	Awaited<ReturnType<ReturnType<typeof getAromoshelfAdminAPI>['perfumerUpdate']>>
>;
export type PerfumerDeleteResult = NonNullable<
	Awaited<ReturnType<ReturnType<typeof getAromoshelfAdminAPI>['perfumerDelete']>>
>;
export type FragranceGetAllResult = NonNullable<
	Awaited<ReturnType<ReturnType<typeof getAromoshelfAdminAPI>['fragranceGetAll']>>
>;
export type FragranceCreateResult = NonNullable<
	Awaited<ReturnType<ReturnType<typeof getAromoshelfAdminAPI>['fragranceCreate']>>
>;
export type FragranceGetOneResult = NonNullable<
	Awaited<ReturnType<ReturnType<typeof getAromoshelfAdminAPI>['fragranceGetOne']>>
>;
export type FragranceUpdateResult = NonNullable<
	Awaited<ReturnType<ReturnType<typeof getAromoshelfAdminAPI>['fragranceUpdate']>>
>;
export type FragranceDeleteResult = NonNullable<
	Awaited<ReturnType<ReturnType<typeof getAromoshelfAdminAPI>['fragranceDelete']>>
>;
export type FragranceChangePhotoComplaintResult = NonNullable<
	Awaited<ReturnType<ReturnType<typeof getAromoshelfAdminAPI>['fragranceChangePhotoComplaint']>>
>;
export type FragranceExportImagesResult = NonNullable<
	Awaited<ReturnType<ReturnType<typeof getAromoshelfAdminAPI>['fragranceExportImages']>>
>;
export type FragranceImportResult = NonNullable<
	Awaited<ReturnType<ReturnType<typeof getAromoshelfAdminAPI>['fragranceImport']>>
>;
export type FragranceSetSuperFragranceResult = NonNullable<
	Awaited<ReturnType<ReturnType<typeof getAromoshelfAdminAPI>['fragranceSetSuperFragrance']>>
>;
export type FragranceGetUrlTitleResult = NonNullable<
	Awaited<ReturnType<ReturnType<typeof getAromoshelfAdminAPI>['fragranceGetUrlTitle']>>
>;
export type FragranceExportCSVResult = NonNullable<
	Awaited<ReturnType<ReturnType<typeof getAromoshelfAdminAPI>['fragranceExportCSV']>>
>;
export type FragranceGetExperiencesResult = NonNullable<
	Awaited<ReturnType<ReturnType<typeof getAromoshelfAdminAPI>['fragranceGetExperiences']>>
>;
export type FragranceGetReviewsResult = NonNullable<
	Awaited<ReturnType<ReturnType<typeof getAromoshelfAdminAPI>['fragranceGetReviews']>>
>;
export type FragranceChangeReviewStatusResult = NonNullable<
	Awaited<ReturnType<ReturnType<typeof getAromoshelfAdminAPI>['fragranceChangeReviewStatus']>>
>;
export type FeedbackGetAllResult = NonNullable<
	Awaited<ReturnType<ReturnType<typeof getAromoshelfAdminAPI>['feedbackGetAll']>>
>;
export type FeedbackUpdateStatusResult = NonNullable<
	Awaited<ReturnType<ReturnType<typeof getAromoshelfAdminAPI>['feedbackUpdateStatus']>>
>;
export type FeedbackReplyResult = NonNullable<
	Awaited<ReturnType<ReturnType<typeof getAromoshelfAdminAPI>['feedbackReply']>>
>;
export type OlfactiveGetNamesResult = NonNullable<
	Awaited<ReturnType<ReturnType<typeof getAromoshelfAdminAPI>['olfactiveGetNames']>>
>;
export type UploadGetUrlsResult = NonNullable<
	Awaited<ReturnType<ReturnType<typeof getAromoshelfAdminAPI>['uploadGetUrls']>>
>;
export type UploadPhotoResult = NonNullable<
	Awaited<ReturnType<ReturnType<typeof getAromoshelfAdminAPI>['uploadPhoto']>>
>;
export type UploadImagesResult = NonNullable<
	Awaited<ReturnType<ReturnType<typeof getAromoshelfAdminAPI>['uploadImages']>>
>;
export type ChangeLogGetAllResult = NonNullable<
	Awaited<ReturnType<ReturnType<typeof getAromoshelfAdminAPI>['changeLogGetAll']>>
>;
export type PushCampaignGetAllResult = NonNullable<
	Awaited<ReturnType<ReturnType<typeof getAromoshelfAdminAPI>['pushCampaignGetAll']>>
>;
export type PushCampaignCreateResult = NonNullable<
	Awaited<ReturnType<ReturnType<typeof getAromoshelfAdminAPI>['pushCampaignCreate']>>
>;
export type PushCampaignGetOneResult = NonNullable<
	Awaited<ReturnType<ReturnType<typeof getAromoshelfAdminAPI>['pushCampaignGetOne']>>
>;
export type PushCampaignUpdateResult = NonNullable<
	Awaited<ReturnType<ReturnType<typeof getAromoshelfAdminAPI>['pushCampaignUpdate']>>
>;
export type PushCampaignDeleteResult = NonNullable<
	Awaited<ReturnType<ReturnType<typeof getAromoshelfAdminAPI>['pushCampaignDelete']>>
>;
export type PushCampaignStopResult = NonNullable<
	Awaited<ReturnType<ReturnType<typeof getAromoshelfAdminAPI>['pushCampaignStop']>>
>;
export type SnapshotGetAllResult = NonNullable<
	Awaited<ReturnType<ReturnType<typeof getAromoshelfAdminAPI>['snapshotGetAll']>>
>;
export type SnapshotCreateResult = NonNullable<
	Awaited<ReturnType<ReturnType<typeof getAromoshelfAdminAPI>['snapshotCreate']>>
>;
export type SnapshotRestoreResult = NonNullable<
	Awaited<ReturnType<ReturnType<typeof getAromoshelfAdminAPI>['snapshotRestore']>>
>;
export type SnapshotDeleteResult = NonNullable<
	Awaited<ReturnType<ReturnType<typeof getAromoshelfAdminAPI>['snapshotDelete']>>
>;
export type SnapshotCancelResult = NonNullable<
	Awaited<ReturnType<ReturnType<typeof getAromoshelfAdminAPI>['snapshotCancel']>>
>;
export type IngredientGetAllResult = NonNullable<
	Awaited<ReturnType<ReturnType<typeof getAromoshelfAdminAPI>['ingredientGetAll']>>
>;
export type IngredientCreateResult = NonNullable<
	Awaited<ReturnType<ReturnType<typeof getAromoshelfAdminAPI>['ingredientCreate']>>
>;
export type IngredientGetNamesResult = NonNullable<
	Awaited<ReturnType<ReturnType<typeof getAromoshelfAdminAPI>['ingredientGetNames']>>
>;
export type IngredientGetOneResult = NonNullable<
	Awaited<ReturnType<ReturnType<typeof getAromoshelfAdminAPI>['ingredientGetOne']>>
>;
export type IngredientUpdateResult = NonNullable<
	Awaited<ReturnType<ReturnType<typeof getAromoshelfAdminAPI>['ingredientUpdate']>>
>;
export type IngredientDeleteResult = NonNullable<
	Awaited<ReturnType<ReturnType<typeof getAromoshelfAdminAPI>['ingredientDelete']>>
>;
export type UserGetAllResult = NonNullable<Awaited<ReturnType<ReturnType<typeof getAromoshelfAdminAPI>['userGetAll']>>>;
export type UserGetOneResult = NonNullable<Awaited<ReturnType<ReturnType<typeof getAromoshelfAdminAPI>['userGetOne']>>>;
export type UserDeleteResult = NonNullable<Awaited<ReturnType<ReturnType<typeof getAromoshelfAdminAPI>['userDelete']>>>;
export type UserGetExperiencesResult = NonNullable<
	Awaited<ReturnType<ReturnType<typeof getAromoshelfAdminAPI>['userGetExperiences']>>
>;
export type UserGetExperienceResult = NonNullable<
	Awaited<ReturnType<ReturnType<typeof getAromoshelfAdminAPI>['userGetExperience']>>
>;
export type UserSetStatusResult = NonNullable<
	Awaited<ReturnType<ReturnType<typeof getAromoshelfAdminAPI>['userSetStatus']>>
>;
export type UserSetCommentResult = NonNullable<
	Awaited<ReturnType<ReturnType<typeof getAromoshelfAdminAPI>['userSetComment']>>
>;
export type UserChangeRoleResult = NonNullable<
	Awaited<ReturnType<ReturnType<typeof getAromoshelfAdminAPI>['userChangeRole']>>
>;
export type UserGetPostsResult = NonNullable<
	Awaited<ReturnType<ReturnType<typeof getAromoshelfAdminAPI>['userGetPosts']>>
>;
export type UserSendMessageResult = NonNullable<
	Awaited<ReturnType<ReturnType<typeof getAromoshelfAdminAPI>['userSendMessage']>>
>;
export type PostGetAllResult = NonNullable<Awaited<ReturnType<ReturnType<typeof getAromoshelfAdminAPI>['postGetAll']>>>;
export type PostCreateResult = NonNullable<Awaited<ReturnType<ReturnType<typeof getAromoshelfAdminAPI>['postCreate']>>>;
export type PostGetOneResult = NonNullable<Awaited<ReturnType<ReturnType<typeof getAromoshelfAdminAPI>['postGetOne']>>>;
export type PostUpdateResult = NonNullable<Awaited<ReturnType<ReturnType<typeof getAromoshelfAdminAPI>['postUpdate']>>>;
export type PostDeleteResult = NonNullable<Awaited<ReturnType<ReturnType<typeof getAromoshelfAdminAPI>['postDelete']>>>;
export type PostModerateResult = NonNullable<
	Awaited<ReturnType<ReturnType<typeof getAromoshelfAdminAPI>['postModerate']>>
>;
export type StopWordsGetResult = NonNullable<
	Awaited<ReturnType<ReturnType<typeof getAromoshelfAdminAPI>['stopWordsGet']>>
>;
export type StopWordsSaveResult = NonNullable<
	Awaited<ReturnType<ReturnType<typeof getAromoshelfAdminAPI>['stopWordsSave']>>
>;
export type RecommendsGetListsResult = NonNullable<
	Awaited<ReturnType<ReturnType<typeof getAromoshelfAdminAPI>['recommendsGetLists']>>
>;
export type RecommendsSaveListsResult = NonNullable<
	Awaited<ReturnType<ReturnType<typeof getAromoshelfAdminAPI>['recommendsSaveLists']>>
>;
export type SettingsGetResult = NonNullable<
	Awaited<ReturnType<ReturnType<typeof getAromoshelfAdminAPI>['settingsGet']>>
>;
export type SettingsSetResult = NonNullable<
	Awaited<ReturnType<ReturnType<typeof getAromoshelfAdminAPI>['settingsSet']>>
>;
export type AdminHealthCheckResult = NonNullable<
	Awaited<ReturnType<ReturnType<typeof getAromoshelfAdminAPI>['adminHealthCheck']>>
>;
