import { LinearProgress, MenuItem, TextField } from '@mui/material';
import {
	DataGrid,
	GridCsvExportMenuItem,
	GridExportMenuItemProps,
	GridPrintExportMenuItem,
	GridToolbarExportContainer,
} from '@mui/x-data-grid';
import { observer } from 'mobx-react-lite';
import { useEffect } from 'react';
import { API } from '~/api';
import { AppRoutes } from '~/app/app.routes';
import { AuthService } from '~/service/service.auth';
import { FragranceService } from '~/service/service.fragrance';
import { UIService } from '~/service/service.ui';
import { FTSStrategy } from '~/shared/dto/paging.dto';
import { Enum } from '~/shared/fields';
import { useService } from '~/shared/service/service.base';
import { ImportFragranceDialog } from '~/view/dialog/dialog.import';
import {
	ActionsColumn,
	AvatarColumn,
	CheckedColumn,
	DateTimeColumn,
	EnumColumn,
	LinkColumn,
	useGridColumns,
} from '~/view/grid/grid.columns';
import { GridContainer } from '~/view/grid/grid.container';
import { GridNoRows } from '~/view/grid/grid.norows';
import { MuiGridPagination } from '~/view/grid/grid.pagination';
import { GridToolbarView } from '~/view/grid/grid.toolbar';
import { Row } from '~/view/view.box';
import { PerformTimeView } from '~/view/view.perform.time';

export const FragranceListPage = observer(() => {
	const [fragrances, ui, auth] = useService(FragranceService, UIService, AuthService);
	const columns = useGridColumns<API.FragranceItem>(() => [
		AvatarColumn({
			field: 'photo',
			headerName: 'Photo',
			filterable: false,
			tooltip: row => row.relevance,
		}),
		CheckedColumn({ field: 'active', headerName: 'Active', filterable: false }),
		CheckedColumn({ field: 'discontinued', headerName: 'Discontinued', filterable: false }),
		{ field: 'id', headerName: 'ID', filterable: false },
		{ field: 'name', headerName: 'Name', flex: 1, minWidth: 200, filterable: false },
		{ field: 'brandName', headerName: 'Brand', minWidth: 150, filterable: true },
		DateTimeColumn({ field: 'photoUpdateAt', headerName: 'Photo updated at' }),
		EnumColumn('Gender', { field: 'gender', headerName: 'Gender', minWidth: 100, filterable: false }),
		EnumColumn('Longevity', { field: 'longevity', headerName: 'Longevity', width: 100, filterable: false }),
		{ field: 'olfactiveName', headerName: 'Olfactive family', width: 150, filterable: false },
		EnumColumn('Sillage', { field: 'sillage', headerName: 'Sillage', minWidth: 100, filterable: false }),
		{ field: 'years', headerName: 'Years', filterable: false },
		{ field: 'perfumerNames', headerName: 'Perfumers', sortable: false, minWidth: 100, filterable: false },
		{ field: 'description', headerName: 'Description', flex: 1, minWidth: 200, filterable: false },
		LinkColumn({
			field: 'superId',
			headerName: 'Super',
			getLink: row => AppRoutes.FragranceEdit.buildPath({ id: row.superId ?? 0 }),
		}),
		ActionsColumn({
			editRoute: AppRoutes.FragranceEdit.buildPath,
			onDelete: fragrances.delete,
			permission: 'fragrance',
		}),
	]);

	ui.usePageTitle('Fragrances');
	useEffect(() => fragrances.loadAndSubscribe(), [fragrances]);

	return (
		<GridContainer async={fragrances.async}>
			<DataGrid
				initialState={fragrances.gridState.initial()}
				loading={fragrances.async.loading}
				rowCount={fragrances.totalCount}
				rows={fragrances.items.slice()}
				columns={columns}
				density={fragrances.gridState.density}
				paginationMode="server"
				filterMode="server"
				sortingMode="server"
				onStateChange={fragrances.gridState.onStateChange}
				onFilterModelChange={fragrances.changeFilterModel}
				onPaginationModelChange={fragrances.gridState.onPaginationModelChange}
				paginationModel={fragrances.gridState.paginationModel}
				slots={{
					loadingOverlay: LinearProgress,
					noRowsOverlay: () => <GridNoRows>No fragrances found</GridNoRows>,
					pagination: MuiGridPagination,
					toolbar: () => (
						<GridToolbarView
							onReload={fragrances.reload}
							gridState={fragrances.gridState}
							onCreateUrl={
								auth.can('fragrance', 'CREATE') ? AppRoutes.FragranceNew.buildPath({}) : undefined
							}
							exportEnabled={false}
							rightMenu={auth.can('fragrance', 'EXPORT') ? <GridToolbarExport /> : undefined}
						>
							<Row ml={4} minWidth={300} gap={2}>
								<TextField
									select
									fullWidth
									label="Search strategy"
									value={fragrances.searchStrategy}
									onChange={fragrances.changeSearchStrategy}
								>
									{Enum.keys(FTSStrategy).map(s => (
										<MenuItem key={s} value={s}>
											{s}
										</MenuItem>
									))}
								</TextField>
							</Row>
						</GridToolbarView>
					),
				}}
			/>
			<PerformTimeView time={fragrances.searchTime} />
			<ImportFragranceDialog />
		</GridContainer>
	);
});

function ImagesExportMenuItem(props: GridExportMenuItemProps<{}>) {
	const [fragrances] = useService(FragranceService);
	const { hideMenu } = props;

	return (
		<MenuItem
			onClick={() => {
				fragrances.exportImages();
				hideMenu?.();
			}}
		>
			Export Images
		</MenuItem>
	);
}

function ImportCSVMenuItem(props: GridExportMenuItemProps<{}>) {
	const [ui] = useService(UIService);
	const { hideMenu } = props;

	return (
		<MenuItem
			onClick={() => {
				hideMenu?.();
				ui.showDialog(ImportFragranceDialog);
			}}
		>
			Import from CSV
		</MenuItem>
	);
}

function ExportCSVMenuItem(props: GridExportMenuItemProps<{}>) {
	const [fragSvc, authSvc] = useService(FragranceService, AuthService);
	const { hideMenu } = props;

	return authSvc.can('fragrance', 'EXPORT') ? (
		<MenuItem
			onClick={() => {
				hideMenu?.();
				fragSvc.exportCSV();
			}}
		>
			Export to CSV
		</MenuItem>
	) : null;
}

function GridToolbarExport({ csvOptions, printOptions, ...other }: any) {
	return (
		<GridToolbarExportContainer {...other}>
			<GridCsvExportMenuItem options={csvOptions} />
			<GridPrintExportMenuItem options={printOptions} />
			<ImagesExportMenuItem />
			<ExportCSVMenuItem />
			<ImportCSVMenuItem />
		</GridToolbarExportContainer>
	);
}
