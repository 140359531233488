import { AlertColor } from '@mui/material';
import dayjs from 'dayjs';
import { action, makeObservable, observable } from 'mobx';
import { FC, useEffect } from 'react';
import { singleton } from 'tsyringe';
import { API } from '~/api';
import { AppRoutes } from '~/app/app.routes';
import { EnvService } from './service.env';

@singleton()
export class UIService {
	@observable.struct pageTitle = '';
	@observable notification?: {
		message: string;
		severity: AlertColor;
		vertical?: 'bottom' | 'top';
		visible?: boolean;
	} = undefined;
	@observable.ref dialog?: FC = undefined;
	@observable mainSubMenu?: string = undefined;

	constructor(private env: EnvService) {
		makeObservable(this);
	}

	@action.bound setMainSubMenu(subMenu: string) {
		this.mainSubMenu = subMenu;
	}

	@action.bound hideMainSubMenu() {
		this.mainSubMenu = undefined;
	}

	@action notify(message: string, severity: AlertColor = 'info', vertical: 'bottom' | 'top' = 'bottom') {
		this.notification = { message, severity, vertical, visible: true };
	}

	@action clearNotification = () => {
		if (this.notification) this.notification.visible = false;
	};

	@action setPageTitle(title: string) {
		document.title = this.pageTitle = `${this.env.PAGE_TITLE}: ${title}`;
	}

	@action.bound showDialog(dialog: FC<any>) {
		this.dialog = dialog;
	}

	@action.bound hideDialog() {
		this.dialog = undefined;
	}

	usePageTitle = (title: string | undefined) => {
		useEffect(() => {
			this.setPageTitle(title ?? '');
		}, [title]);
	};

	formatDateTime(date: Date | undefined | null) {
		const dt = dayjs(date ?? '');
		return dt.isValid() ? dt.format('YYYY-MM-DD HH:mm:ss') : '-';
	}

	openHistory(entity: API.ChangeLogEntity, id: number | string) {
		window.open(AppRoutes.ChangeLog.buildPath({}, { entity, id: id.toString() }), '_blank');
	}

	confirmDelete(proceed: () => void) {
		if (
			prompt('Are you sure to delete this entity with ALL DEPENDENCIES? Type YES to confirm.')?.toLowerCase() ===
			'yes'
		) {
			proceed();
		} else {
			this.notify('Delete cancelled', 'warning');
		}
	}

	confirm(message: string, proceed: () => void) {
		// eslint-disable-next-line no-restricted-globals
		if (confirm(message)) {
			proceed();
		} else {
			this.notify('Cancelled', 'warning');
		}
	}

	storeFile = ({ content, type, name }: { content: string; type: string; name: string }) => {
		const blob = new Blob([content], { type });
		const data = window.URL.createObjectURL(blob);

		const link = document.createElement('a');
		link.href = data;
		link.download = name;
		link.dispatchEvent(new MouseEvent('click', { bubbles: true, cancelable: true, view: window }));

		setTimeout(() => {
			window.URL.revokeObjectURL(data);
			link.remove();
		}, 100);
	};
}
